// ------------------------------------------------------------
// Meta data styles
// ------------------------------------------------------------

@use "sass:math";

.meta, .meta-new {
    .hero & {
        margin-top: 2rem;
        .meta__author__job {
            opacity: 0.75;
        }
	}
	.l-sidebar__aside & {
		margin-top: 2em;
		margin-right: 2em;
	}
	.m-reveal & {
		margin-top: 2em;
	}
}

.meta__update {
    position: relative;
    color: #fff;
    padding-left: 2.5em;
    @include font-size($para + 1);
    span {
        position: relative;
        display: block;
        text-transform: uppercase;
        letter-spacing: 2px;
        @include font-size($eta + 1);
        &:before {
            content: "";
            position: absolute;
    		top: -5px;
    		left: 0;
    		width: 100%;
    		height: 3px;
    		background: #fff;
        }
        + span {
            &:before {
                content: none;
            }
		}
		.m-reveal & {
			&:before {
				content: none;
			}
		}
    }
    &:before {
        content: "";
        background-image: url(/assets/svg/source/icon-calendar.svg);
        background-size: contain;
        width: 22px;
        height: 22px;
        position: absolute;
        top: -7px;
        left: 0;
        display: block;
	}
	.l-sidebar & {
		color: $base-color;
		&:before {
			background-image: url(/assets/svg/source/icon-calendar-slate.svg);
		}
	}
	.m-reveal & {
		&:before {
			top: 0;
		}
	}
}

.meta__date {
	display: inline-block;
	position: relative;
	padding-top: 1rem;
	&:after, &:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: $border-width;
		background: #fff;
		transition: $transition;
		transition-delay: 100ms;
	}
	&:after {
		width: 0;
		background: $action;
	}
    svg {
        width: 18px;
        height: 18px;
        vertical-align: text-bottom;
        margin-right: 5px;
    }
}

.meta__time {
	margin-left: 15px;
}

.meta__date, .meta__author {
	@include small-caps;
	@include font-size(math.div($eta, 1.25));
	margin-bottom: 1rem;
    .hero & {
        @extend %font-heading;
        letter-spacing: 1.25px;
        font-size: 1.32rem;
    }
}

.meta__author__name {
	display: block;
	@include respond-to($c-vp) {
		display: inline;
	}
}

.meta__author__separator {
  display: none;
  @include respond-to($c-vp) {
    display: inline;
    color: $gnr-concrete;
  }
}

.meta__author__job {
	display: block;
	@include respond-to($c-vp) {
		display: inline;
	}
}

.meta__location {
	position: relative;
	max-width: 750px;
	color: $body-color;
	padding-top: 1rem;
	@include respond-to($c-vp) {
		padding-top: 1.5rem;
	}
	&:after, &:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 1px;
		background: rgba($body-color, 0.25);
		transition: $transition;
	}
	&:after {
		background: $action;
		width: 0;
		left: auto;
		right: 0;
	}
    .hero & {
        display: inline-block;
        @include respond-to($b-vp) {
            margin-left: 15px;
        }
        &:before {
            height: $border-width;
            background: $body-color;
            top: 1px;
            @include respond-to($c-vp) {
                top: 5px;
            }
        }
    }
}

.meta__location__place, .meta__location__region {
	@include small-caps;
	@include font-size(math.div($eta, 1.25));
    .hero & {
        @extend %font-heading;
        letter-spacing: 1.25px;
        font-size: 1.32rem;
    }
}

.meta__location__place {
	color: $body-color;
	margin-left: 5px;
	display: inline-block;
}

.meta__location__region {
	color: $body-color;
	display: inline-block;
	margin-left: 15px;
	opacity: 0.7;
}

.meta__location__icon {
	width: 6px;
	display: inline-block;
}

.meta-new {

}

.meta-new__item {
    position: relative;
    color: #fff;
    padding-left: 2em;
    @include font-size($para);
    margin-bottom: 7px;
    line-height: 2.2rem;
    svg {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 3px;
    }
    a {
        color: #fff;
        border-bottom: 1px solid #fff;
        @include link-active-styles {
            border-bottom: 0;
        }
    }
}
