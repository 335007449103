// ------------------------------------------------------------
// Aside modules
// ------------------------------------------------------------

.aside {
    padding: 2rem 2rem 0 0;
    .l-sidebar--reverse & {
        padding: 0;
    }
    +.aside {
        margin-top: 2em;
    }
    &--top {
        padding-top: 0.25rem;
    }
}

.aside__heading {
    @extend .type-m;
    @extend .heading;
    margin-bottom: 1em;
    .filter & {
        @include respond-to-max($sidebar-respond) {
            display: none;
        }
    }
}

.aside__copy {
    @include font-size($epsilon);
    margin-bottom: 1em;
}

.aside__list {
    @include font-size($epsilon);
    li {
        margin-bottom: 0.5em;
        a {
            word-wrap: break-word;
            text-decoration: underline;
        }
    }
}

.aside__list__heading {
    @extend %font-heading;
}
