// ------------------------------------------------------------
// Aligment and meter
// ------------------------------------------------------------

@use "sass:math";

.max-meter 				{ max-width: 800px; }
.max-meter--center 		{ margin-left: auto; margin-right: auto; }

.align-center 			{ text-align: center; }
.align-left 			{ text-align: left; }
.align-right 			{ text-align: right; }

.larger-center {
	max-width: 850px;
	margin-left: auto;
	margin-right: auto;
	@include respond-to($e-vp) {
		max-width: 900px;
	}
}

// ------------------------------------------------------------
// Additional typesetting
// ------------------------------------------------------------

// Make articles text more legible
.is-typeset.is-typeset--article {
	@include respond-to($menu-respond) {
		&.is-typeset--additionals {
			> p:first-of-type {
				@include font-size($para*1.35);
			}
		}
		p, li {
			 @include font-size($para*1.25);
		}
		h1, h2 {
			@include font-size($alpha*1.25);
		}
		h3 {
            @include font-size($beta*1.25);
        }
        h4 {
			@include font-size($gamma*1.25);
		}
		h5 {
			@include font-size($delta*1.25);
		}
		h6 {
			@include font-size($epsilon*1.25);
		}
		blockquote {
			p {
				@include font-size($quote*1.1);
			}
		}
		figcaption {
			@include font-size($para - 1);
			margin-bottom: 2em;
			p {
				margin-bottom: 0;
			}
			p, a, ul {
				@include font-size($para - 1);
			}
		}
	}
}

.is-typeset--additionals {
	> p:first-of-type {
		@extend %font-heading;
		@include font-size($para*1.25);
	}
}

sup {
	position: relative;
	top: -.5em;
	@include font-size($para);
}

// ------------------------------------------------------------
// Blockquotes
// ------------------------------------------------------------

.as-blockquote {
	position: relative;
	padding: 2em 0;
	&:before, &:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 10px;
		width: 10em;
		background-color: $gnr-coral;
		display: block;
	}
	&:after {
		top: auto;
		bottom: 1.2em;
	}
	p {
		@extend %font-heading;
		@include font-size(math.div($quote, 1.25));
		color: $gnr-coral;
	}
	cite {
		display: block;
		color: $gnr-coral;
	}
}

.is-typeset {
	blockquote {
		@extend .as-blockquote;
		@include respond-to($menu-respond) {
			float: right;
			width: 50%;
			display: block;
			margin-left: 3em;
		}
	}
}

// ------------------------------------------------------------
// Library CMS control. Delete / Add as required
// ------------------------------------------------------------

@import "libs/wagtail";
@import "libs/wordpress-wysiwyg";
//@import "libs/wordpress-paging";
//@import "libs/wordpress-comments";
