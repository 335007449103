// ------------------------------------------------------------
// Search
// ------------------------------------------------------------

.search-toggle {
    position: absolute;
    top: 3.5em;
    right: $internal-spacing*1.5;
    outline: none;
    display: block;
    @include respond-to($menu-respond) {
        display: none;
    }
    .no-js & {
        display: none;
    }
}

.search {
    @extend %pie-clearfix;
    position: relative;
    margin-top: 1.5em;
    display: none;
    overflow: hidden;
    input {
        border-radius: 1.5em;
        border: none;
        padding-left: 1.5em;
        padding-right: 7em;
        height: 48px;
        width: 100%;
        float: right;
        transition: all, ease-in-out, 0.3s;
        &.js-search--active {
            width: 80%;
        }
    }
    button {
        border-top-right-radius: 45%;
        border-bottom-right-radius: 45%;
        width: auto;
        padding: 2.3em;
        position: absolute;
        top: 0;
        right: 0;
        border: none;
    }
    &--active {
        display: block;
    }
    .no-js & {
        display: block;
    }
    @include respond-to($menu-respond) {
        display: block;
        margin-bottom: 1.5em;
        margin-top: 1em;
        input {
            width: 60%;
        }
    }
}