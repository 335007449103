// ------------------------------------------------------------
// Specific sizes
// ------------------------------------------------------------

.iconlist {

}

.iconlist__item{
    margin-top: 10px;
    position: relative;
    @include font-size($delta);
    padding: 0 20px 0 30px;
    color: $gnr-slate;
    // min-height: 30px;
    .iconlist--status & {
        color: $gnr-slate;
        // svg {
        //     // filter: brightness(0) saturate(100%) invert(74%) sepia(14%) saturate(291%) hue-rotate(170deg) brightness(90%) contrast(91%);
        // }
        &--active {
            // svg {
            //     filter: none;
            // }
        }
    }
    svg {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 5px;
    }
    &--large {
        @include font-size($gamma);
        padding: 10px 20px 0 80px;
        min-height: 60px;
        margin-top: 20px;
        svg {
            width: 60px;
            height: 60px;
            top: 0;
        }
    }
}
