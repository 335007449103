// ------------------------------------------------------------
// reportCard
// ------------------------------------------------------------
@use "sass:math";

.reportCard-link {
	display: block;
	background: $gnr-slate;
	color: $body-color;
	margin-bottom: 2rem;
	&:last-of-type {
		margin-bottom: 0;
	}
	@include respond-to($b-vp) {
		margin-bottom: 0;
	}
	&:hover {
		.reportCard__sub {
			&:after {
				width: 10%;
				left: 0;
				right: auto;
			}
		}
		.button {
			color: $body-color;
			border-color: $action;
			background: $action;
		}
	}
}


.reportCard {
	padding: 2rem;
}

.reportCard__content {
	height: 100%;	
}

.reportCard__heading {
	color: $gnr-cloud;
	@extend .heading;
	@include small-caps;
	margin-bottom: 3.5rem;
	opacity: 0.75;
}

.reportCard__sub {
	position: relative;
	margin-bottom: 0.75rem;
	padding-top: 2rem;
	line-height: 1.4;
	color: $body-color;
	transition: $transition;
	&:after, &:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 10%;
		border-top: $border-width solid $body-color;
		transition: $transition;
	}
	&:after {
		border-color: $action;
		width: 0;
		left: auto;
		right: 90%;
	}
}

.reportCard__intro {
	color: $body-color;
	margin-bottom: 1.5rem;
	@include font-size($eta*1.2);
}

.reportCard__action {
	@include font-size(math.div($eta, 1.25));
}

