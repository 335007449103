// Basic responsive videos
// ------------------------------------------------------------

.media-video-wrapper,
.media-wrapper {
	overflow: hidden;
	position: relative;
	@include maintain-ratio(16 9);
	object,embed,iframe  {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.is-typeset & {
		margin: 2em 0;
	}
}

.media-figure {
	.is-typeset & {
		margin: 2em 0;
	}
	img {
		display: block;
		margin-bottom: 0.5em;
		width: 100%;
		height: auto;
	}
	&__caption {
		@include font-size($para - 2);
		display: block;
		padding: 0.5em 1em;
		border-left: 3px solid $gnr-sunset;
	}
	&--data {
		img {
			display: none;
			@include respond-to($b-vp) {
				display: block;
			}
		}
		table {
			@include respond-to($b-vp) {
				display: none;
			}
		}
	}
}

.media-caption {
	@include font-size($para - 2);
	border-left: 3px solid $action;
	padding-left: 1em;
	margin-top: 1em;
	a {
		@extend %font-heading;
		text-decoration: underline;
	}
	.is-typeset & {
		ul {
			list-style: none;
			padding-left: 0;
		}
	}
	.is-typeset--article & {
		@include font-size($para - 2);
	}
}
