// ------------------------------------------------------------
// Data
// ------------------------------------------------------------

.data {
    background-color: #fff;
}

.data__headings {
    display: none;
    @include respond-to($c-vp) {
        display: flex;
        margin-left: 40%;
        width: 100%;
    }
}

.data__heading {
    @extend %font-heading;
    @include font-size($delta);
    padding: 1.5em;
    width: 40%;
    &:last-child {
        width: 20%;
    }
}

.data__section {

}

.data__section-heading {
    @include font-size($delta);
    @extend %font-heading;
    background-color: $gnr-concrete;
    color: #fff;
    display: block;
    width: 100%;
    padding: 0.5em 1em;
}

.data__items {
    border-bottom: 3px solid $gnr-sky-light;
    &:last-of-type {
        border-bottom: none;
    }
    @include respond-to($c-vp) {
        display: flex;
    }
    &--highlight {
        background-color: lighten($gnr-cloud, 10%);
    }
}

.data__item {
    @include font-size($para);
    padding: 1em 1.5em;
    p + p {
        margin-top: 0.75em;
    }
    &:first-of-type {
        @extend %font-heading;
    }
    @include respond-to($c-vp) {
        padding: 1.5em;
        width: 40%;
        &:last-of-type {
            width: 20%;
        }
    }
    &--on-course {
        background-color: $gnr-sky;
    }
    &--reached-commitment {
        background-color: $gnr-aqua;
    }
    &--off-course {
        background-color: $gnr-sunrise;
    }
    &--undetermined {
        background-color: $gnr-cloud;
    }
    &--no-response {
        background-color: $gnr-cloud;
    }
    &--not-assessable {
        background-color: $gnr-cloud;
    }
    &--not-applicable {
        background-color: $gnr-cloud;
    }
}

.table-styled table {
    &.data__table {
        table-layout: fixed;
        td {
            vertical-align: top;
        }
    }
}

.data__row {
    .data__explanation {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-top: 10px;

        &--minimal {
            margin-top: 2px;
        }

        button.button {
            color: #475c6d;
            padding: 0;
            text-decoration: underline;
        }

        span[data-truncated], span[data-full] {
            margin-right: 8px;
        }
    }
}

.data__subheading {
    @extend %font-heading;
    display: block;
    @include respond-to($c-vp) {
        display: none;
    }
    .data__item--off-course &, .data__item--reached & {
        color: #fff;
    }
}

.data__note {
    margin-top: 1em;
    span {
        @extend %font-heading;
        display: block;
    }
    .data__item--off-course & {
        color: #fff;
    }
}

.data__flag {
    @extend %font-heading;
    @include font-size($eta);
    text-transform: uppercase;
    color: #fff;
    display: inline-block;
    background-size: 16px 16px;
    background-position: 0 1px;
    background-repeat: no-repeat;
    padding-left: 25px;
    .data__item--on-course &,
    .data__item--undetermined &,
    .data__item--no-response &,
    .data__item--reached-commitment &,
    .data__item--not-assessable &,
    .data__item--not-applicable & {
        color: darken($gnr-slate, 5%);
    }
    .data__item--reached-commitment & {
        background-image: url(../svg/source/icon-tick-slate.svg);
    }
    .data__item--off-course & {
        background-image: url(../svg/source/icon-off-course.svg);
    }
    .data__item--on-course & {
        background-image: url(../svg/source/icon-tick-slate.svg);
    }
    .data__item--undetermined & {
        background-image: url(../svg/source/icon-nodata.svg);
    }
    .data__item--not-assessable & {
        background-image: url(../svg/source/icon-nodata.svg);
    }
    .data__item--not-applicable & {
        background-image: url(../svg/source/icon-nodata.svg);
    }
    .data__item--no-response & {
        background-image: url(../svg/source/icon-cross-slate.svg);
    }

    .data__row--reached & {
        color: $gnr-aqua;
        background-image: url(../svg/source/icon-tick-slate.svg);
    }
    .data__row--off-course & {
        color: $gnr-sunrise;
        background-image: url(../svg/source/icon-off-course-sunrise.svg);
    }
    .data__row--on-course & {
        color: $gnr-sky;
        background-image: url(../svg/source/icon-tick-slate.svg);
    }
    .data__row--undetermined & {
        color: $gnr-concrete;
        background-image: url(../svg/source/icon-nodata.svg);
    }
    .data__row--not-assessable & {
        color: $gnr-concrete;
        background-image: url(../svg/source/icon-nodata.svg);
    }
    .data__row--not-applicable & {
        color: $gnr-concrete;
        background-image: url(../svg/source/icon-nodata.svg);
    }
    .data__row--no-response & {
        color: $gnr-slate;
        background-image: url(../svg/source/icon-cross-slate.svg);
    }
}

.data__tag {
    @extend %font-heading;
    @include font-size($eta);
    text-transform: uppercase;
    display: block;
    margin-bottom: 1em;
    span {
        border-top: 3px solid $gnr-slate;
        display: inline;
        padding-top: 5px;
    }
}

.data__date {
    @include font-size($eta);
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.5em;
    color: $gnr-concrete;
}
