// ------------------------------------------------------------
// horizontalListing
// ------------------------------------------------------------
.search-term {
	@extend %font-sans;
}


.horizontalListing {
	position: relative;
}

.horizontalListing__item {
	position: relative;
	margin-bottom: 3rem;
	&:last-of-type {
		margin-bottom: 0;
	}
	&:hover {
		.horizontalListing__wrapper {
			&:after {
				width: 60px;
				right: auto;
				left: 0;
			}
		}
		.horizontalListing__title {
			color: $action;
		}
		.horizontalListing__tag {
			background: $action;
		}
	}
}

.horizontalListing__wrapper {
	position: relative;
	display: block;
	padding: 3rem 0;
	&:after, &:before {
		content: '';
		position: absolute;
		top: 0;
		right: calc(100% - 60px);
		width: 60px;
		height: $border-width;
		background: $gnr-slate;
		transition: $transition;
		transition-delay: 100ms;
	}
	&:after {
		width: 0;
		background: $action;
	}
}

.horizontalListing__tag {
	position: absolute;
	right: 0;
	top: 0;
	display: inline-block;
	color: $body-color;
	background: $gnr-sunrise;
	transition: $transition;
	padding: 0.5rem 2rem;
	@include small-caps;
	@extend .heading;
}

.horizontalListing__chapter {
	@include small-caps;
	color: $gnr-slate;
}

.horizontalListing__title {
	margin-bottom: 1rem;
	transition: $transition;
	line-height: 1.3;
}

.horizontalListing__intro {
	color: $gnr-slate;
}

.horizontalListing-highlight {
	padding: 0.5rem 0;
	background: rgba($gnr-sunrise, 0.5);
	transition: $transition;
}
