// ------------------------------------------------------------
// Slider
// ------------------------------------------------------------

.slider-wrapper {
  @include respond-to($c-vp) {
    position: relative;
    left: 0;
    // top: 50%;
    // transform: translateY(-50%);
    // -webkit-transform: translateY(-50%);
    z-index: 1;
    margin-bottom: 3rem;
    width: 100%;
  }
}

.slider-media {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: linear-gradient(to left, rgba(#000, 0.25) 0%,rgba(#000, 0) 70%);
        transition: $transition;
    }
    &.active {
        &:after {
            opacity: 1;
        }
    }
}

.slider-media__image {
    display: none;
    @extend .slider-media;
    @include respond-to($c-vp) {
        display: block;
        background-size: cover;
        background-position: center;
        filter: blur(50px);
        opacity: 0;
        transition: $transition-slow;
        &.active {
          filter: blur(0);
          opacity: 1;
        }
    }
}


.slider-content {
  position: relative;
  z-index: 1;
  margin-bottom: 3rem;
  @include respond-to($c-vp) {
    margin-bottom: 0;
    display: block;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 40%;
    max-width: 500px;
    float: left;
  }
  &.click {
    .button-wrapper {
      position: relative;
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.slider-content__item {
  @extend .slider-content;
  width: 100%;
  transition: $transition-slow;
  display: none;
  &:first-of-type {
    display: block;
  }
  @include respond-to($c-vp) {
    display: block;
  }
  &--default {
    max-width: 450px;
    .slider-content__heading {
        color: $gnr-sunrise;
    }
  }
}

.slider-content__heading {
  color: $body-color;
  @include font-size($beta*2);
  @extend .heading;
  line-height: 1.1;
  margin-bottom: 2rem;
  @include respond-to($c-vp) {
    @include font-size($alpha*2);
  }
}

.slider-content__year {
  @extend %font-sans;
}

.slider-content__para {
  position: relative;
  color: $body-color;
  padding-bottom: 2rem;
  @include font-size($delta);
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: $border-width;
    background: $body-color;
  }
  .slider-content__item--default & {
      position: relative;
      color: $body-color;
      padding-bottom: 2rem;
      @include font-size($delta);
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: $border-width;
        background: $body-color;
      }
  }
}

.slider-content__button {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

// hover in effect
.slider-content {
  @include respond-to($c-vp) {
      display: block;
      .contents__chapter__number {
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        opacity: 0;
        transition: $transition-slow;
        transition-delay: 0ms;
      }
      .slider-content__heading {
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        opacity: 0;
        transition: $transition-slow;
        transition-delay: 0ms;
      }
      .slider-content__para {
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        opacity: 0;
        transition: $transition-slow;
        transition-delay: 0ms;
        &:after {
          transition: $transition-fast;
          transition-delay: 0ms;
        }
      }
      &.click .button-wrapper {
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        opacity: 0;
        transition: $transition-slow;
        transition-delay: 0ms;
      }
      &.active {
        pointer-events: auto;
        .contents__chapter__number {
          opacity: 1;
          transform: translateX(0px);
          -webkit-transform: translateX(0px);
          transition-delay: 300ms;
        }
        .slider-content__heading {
          opacity: 1;
          transform: translateX(0px);
          -webkit-transform: translateX(0px);
          transition-delay: 450ms;
        }
        .slider-content__para {
          opacity: 1;
          transform: translateX(0px);
          -webkit-transform: translateX(0px);
          transition-delay: 600ms;
          &:after {
            transition-delay: 700ms;
            width: 50px;
          }
        }
        &.click .slider-content__para {
          &:after {
            transition-delay: 700ms;
            width: 0;
          }
        }
        &.click .button-wrapper {
          transform: translateX(0);
          -webkit-transform: translateX(0);
          opacity: 1;
          transition-delay: 800ms;
        }
      }
  }
}

.slider-nav {
  display: none;
  @include respond-to($c-vp) {
    display: block;
    position: absolute;
    left: 6rem;
    bottom: -50px;
  }
  html.no-js & {
      display: none;
  }
}

.slider-nav__prev, .slider-nav__next {
  float: left;
  padding: 1.5rem 2rem;
  border: 1px solid $body-color;
  background: transparent;
  transition: $transition;
  &:hover {
    background: $action;
    cursor: pointer;
  }
}

.slider-nav__next {
  border-left: none;
}
