// ------------------------------------------------------------
// Logos
// ------------------------------------------------------------

.logo {
    margin-top: 2em;
}

.logo__media {
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 200px;
    border: 1px solid $default-border-color;
    margin-bottom: 1.25em;
    display: block;
    transition: border-color, ease-in, 0.3s;
    @include respond-to($c-vp) {
        background-size: 60%;
    }
    @include respond-to($d-vp) {
        min-height: 150px;
        background-size: 70%;
    }
    &--white{
        background-color: #fff;
    }
}

a.logo__media:hover {
    border-color: $action-respond;
    transition: border-color, ease-in, 0.3s;
}

.logo__title {
    @extend %font-heading;
    @include font-size($delta + 2);
    line-height: 1.3;
    a {
        color: $heading-color;
        &:hover {
            color: $action-respond;
        }
    }
}

.logo__link {
    margin-top: 0.5em;
    overflow-wrap: break-word;
    word-wrap: break-word;
    line-height: 1.3;
    @include font-size($para);
    @include respond-to($c-vp) {
        @include font-size($para + 1);
    }
}
