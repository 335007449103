// ------------------------------------------------------------
// teaser
// ------------------------------------------------------------

@use "sass:math";

.teaser-list {

}

.teaser__item {
	position: relative;
	margin-bottom: 2rem;
	background-color: $gnr-slate;
	@include respond-to($c-vp) {
		margin-bottom: 4rem;
	}
}

.teaser_link {
	@extend %pie-clearfix;
	display: block;
	@include respond-to($c-vp) {
		width: 100%;
	}
	&:hover {
		.meta__date {
			&:after {
				@include respond-to($c-vp) {
					right: auto;
					left: 0;
					width: 100%;
				}
			}
		}
		.meta__location {
			&:after {
				@include respond-to($c-vp) {
					left: 0;
					right: auto;
					width: 100%;
				}
			}
		}
		.teaser__img {
			&:after {
				@include respond-to($c-vp) {
					left: 0;
					right: auto;
					width: 100%;
				}
			}
		}
	}
}

.teaser__content {
	padding: 3rem 2rem 2rem;
	@include respond-to($c-vp) {
		width: 70%;
		float: left;
	}
}

.teaser__tab {
	position: absolute;
	right: 0;
	top:  0;
	display: inline-block;
	color: $body-color;
	background: $gnr-sunset-a11y;
	padding: 0.5rem 2rem;
	@include small-caps;
	@extend .heading;
	.hero & {
        z-index: 1;
        top: auto;
        bottom: 0;
        @include respond-to($menu-respond) {
            right: 50px;
        }
    }
}

// type
.teaser__heading {
	color: $body-color;
	margin: 0.5rem 0;
	@include respond-to($c-vp) {
		margin: 1rem 0;
	}
}

.teaser__intro {
	color: $body-color;
	@include font-size(math.div($delta, 1.25));
	margin-bottom: 1rem;
	@include respond-to($c-vp) {
		margin-bottom: 2.5rem;
	}
}

.teaser__list {
	color: $body-color;
	@include font-size(math.div($delta, 1.25));
	margin-bottom: 1rem;
	@include respond-to($c-vp) {
		margin-bottom: 2.5rem;
	}
	li {
		margin-bottom: 0.25em;
		&:last-child {
			margin-bottom: 0;
		}
		span {
			font-weight: bold;
		}
	}
}

.teaser__img {
	position: relative;
	height: 175px;
	background-size: cover;
	background-position: center;
	@include respond-to($c-vp) {
		width: 30%;
		float: right;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
	}
	&:after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 0;
		height: 100%;
		background: $action;
		mix-blend-mode: color;
		transition: $transition;
		transition-delay: 100ms;
		@supports not (mix-blend-mode: multiply) {
			opacity: 0.75;
		}
	}
	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: $gnr-slate;
		background-image: url(../img/aqua-dots-01.png);
		background-size: 15%;
		html.svg & {
		    background-image: url(../svg/source/aqua-dots-02.svg);
		}
	}
	&--simple {
		@include respond-to-max($c-vp) {
			display: none;
		}
	}
}

// ie 11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.teaser__img:after {
		opacity: 0.75;
	}
}

// no image class
.no-image {
	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: $gnr-slate;
		background-image: url(../img/aqua-dots.png);
		background-size: 2%;
		html.svg & {
		    background-image: url(../svg/source/aqua-dots-02.svg);
		}
	}
}
