$chart-color-1  : #f3a633;
$chart-color-2  : #b8e986;
$chart-color-3  : #81edfb;

.charts {
    display: grid;
    margin-top: 1.5em;
    margin-bottom: 1em;
    &--3up {
        grid-template-columns: 1fr;
        grid-gap: 1em;
        @include respond-to($c-vp) {
            :first-child {
                grid-column: span 3;
            }
        }
        @include respond-to($c-vp) {
            grid-template-columns: repeat(3, 32%);
        }
    }
    &--4up {
        grid-template-columns: 1fr;
        grid-gap: 1em;
        @include respond-to($c-vp) {
            :first-child {
                grid-column: span 3;
            }
        }
        @include respond-to($c-vp) {
            grid-template-columns: repeat(2, 49%);
        }
        @include respond-to($e-vp) {
            grid-template-columns: 8% repeat(4, 22%);
        }
    }
    .alert {
        margin-bottom: 2em;
    }
    &__chart {
        height: 300px;
        &--interactive {
            display: none;
            position: relative;
            padding: 0;
            height: auto;
            svg, .chart {
                position: relative;
            }
            .chart {
                width: 100%;
                height: 100%;
            }
            html.js & {
                display: block;
            }
            .overallselect {
                display: flex;
                overflow: hidden;
                margin-bottom: 1.5em;
                input {
                    position: absolute !important;
                    clip: rect(0, 0, 0, 0);
                    height: 1px;
                    width: 1px;
                    border: 0;
                    overflow: hidden;
                }
                label {
                    background-color: $gnr-cloud;
                    padding: 0.75em 0.75em;
                    @include font-size($para);
                    line-height: 1;
                    text-align: center;
                    margin-right: -1px;
                    transition: all 0.1s ease-in-out;
                    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
                    cursor: pointer;
                }
                input:checked + label {
                    background-color: $gnr-slate;
                    color: #fff;
                    box-shadow: none;
                }
                label:first-of-type {
                    border-radius: 5px 0 0 5px;
                }
                label:last-of-type {
                    border-radius: 0 5px 5px 0;
                }
            }
            .indicatorselect, .disaggregationselect {
                input[type=radio]:not(old){
                    width     : 2em;
                    margin    : 0;
                    padding   : 0;
                    font-size : 1em;
                    opacity   : 0;
                    position: absolute;
                  }
                input[type=radio] + label {
                    padding: 0.5em 0.75em;
                    @include font-size($para);
                    border-radius: 5px;
                    display: inline-block;
                    cursor: pointer;
                    margin-right: 1em;
                    margin-bottom: 1em;
                    background-color: $gnr-cloud;
                }
                input[type=radio]:checked + label {
                    background-color: $gnr-slate;
                    color: #fff;
                }
            }
            .xaxis text {font-size:20px; font-weight: 100; fill:#475C6D; font-family: 'Averta W01 Regular',sans-serif; }
            .yaxis text {font-size:20px; font-weight: 100; fill:#475C6D; font-family: 'Averta W01 Regular',sans-serif; }
            @include respond-to($b-vp) {
                .xaxis text {font-size:15px;}
                .yaxis text {font-size:15px; }
            }
            @include respond-to($c-vp) {
                .xaxis text {font-size:10px;}
                .yaxis text {font-size:10px; }
            }
            .tooltip {pointer-events: none;}
            .tooltip-bg {pointer-events: none;}
            .xaxis text {font-weight: 100; fill:#475C6D; font-family: 'Averta W01 Regular',sans-serif; }
            .xaxis line {stroke:transparent;}
            .xaxis path {stroke:#475C6D;}
            .yaxistitle {font-weight: 100; fill:#CFD9E5; font-family: 'Averta W01 Regular',sans-serif; }
            .yaxis text {font-weight: 100; fill:#CFD9E5; font-family: 'Averta W01 Regular',sans-serif; }
            .yaxis line {stroke:#CFD9E5;}
            .yaxis path {stroke:transparent;}
            .tooltip {pointer-events: none;}
            .tooltip-bg {pointer-events: none;}
        }
    }
    .chart {
        &__value--bar {
            fill: $chart-color-1;
            &:nth-of-type(2) {
                fill: $chart-color-2;
            }
            &:nth-of-type(3) {
                fill: $chart-color-3;
            }
        }
        &__value--line {
            stroke: $chart-color-1;
            &:nth-of-type(2) {
                stroke: $chart-color-2;
            }
            &:nth-of-type(3) {
                stroke: $chart-color-3;
            }
        }
    }
    form {
        input[type=radio]:not(old){
          width     : 2em;
          margin    : 0;
          padding   : 0;
          font-size : 1em;
          opacity   : 0;
          position: absolute;
        }
        input[type=radio] + label {
            background-color: $gnr-slate;
            color: #fff;
            padding: 0.5em 0.75em;
            @include font-size($para);
            border-radius: 5px;
            display: inline-block;
            cursor: pointer;
            margin-right: 1em;
            margin-bottom: 1em;
        }
        input[type=radio]:checked + label {
            background-color: $gnr-sunrise;
            color: #fff;
        }
        input[type=radio]:focus + label {
            outline: $gnr-sunrise auto 5px;
        }
    }
    &--controls {
        margin-bottom: 2em;
        &.inactive {
            display: none;
        }
    }
    &--non-js {
        .js & {
            display: none;
        }
    }
    &--js {
        .no-js & {
            display: none;
        }
    }
    // Interactive fallbacks
    .profile-figure {
        html.js & {
            display: none;
        }
    }
}

/* Bar Chart Style
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.axis path,
.axis line {
  fill: none;
  stroke: #ccc;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}

.tick text {
    font-family:"Averta W01 Bold";
    font-size: 12px;
}

text.value {
    font-family:"Averta W01 Bold";
    font-size: 20px;
    fill: white;
}

text.title {
    font-family:"Averta W01 Bold";
    font-size: 20px;
    fill: #475c6d;
}

/* Charts css only
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen {
    .charts-container {
        display: block;
        &__tabs {
            display: flex;
            flex-wrap: wrap;
            input {
              position: absolute;
              opacity: 0;
            }
            label {
                background-color: $gnr-slate;
                color: #fff;
                padding: 0.5em 0.75em;
                @include font-size($para);
                border-radius: 5px;
                display: inline-block;
                cursor: pointer;
                margin-right: 1em;
                margin-bottom: 1em;
            }
            label:hover {
                background-color: $gnr-sunrise;
                color: #fff;
            }
            label:active {
                background-color: $gnr-sunrise;
                color: #fff;
            }

            input:focus + label {
              box-shadow: inset 0px 0px 0px 3px #2aa1c0;
              z-index: 1;
            }
            input:checked + label {
                background-color: $gnr-sunrise;
                color: #fff;
            }
            .l-3up, .l-4up {
                order: 99;
                display: none;
            }
            input:checked + label + .l-3up {
                display: block;
                @supports (display: grid) {
                    display: grid;
                }
                @supports (display: -ms-grid) {
                    display: block;
                }
            }
            input:checked + label + .l-4up {
                display: block;
                @supports (display: grid) {
                    display: grid;
                }
                @supports (display: -ms-grid) {
                    display: block;
                }
            }
            .m-notes {
                display: block;
            }
        }
    }
}


/* Charts for NAF */

/* Line chart */
.line-chart {
    width: 100%;
    height: 100%;
    overflow: auto;

    .svg-chart {
        height: 100%;
        width: 100%;
    }

    .svg-chart .x-labels {
        text-anchor: middle;
    }

    .svg-chart .y-labels {
        text-anchor: end;
    }

    .svg-chart .svg-chart-grid {
        stroke: #ccc;
        stroke-dasharray: 0;
        stroke-width: 1;
    }

    .label-title {
        text-anchor: middle;
        font-size: 12px;
        fill: gray;
    }

    .svg-chart-dot{
        fill: $gnr-coral;
        stroke-width: 2;
        stroke: white;
        &--target {
            fill: $gnr-sunset;
        }
        &:before {
            content: data(value);
            background: red;
        }
        &:hover {
            background: red;
            &:before {
                content: attr(data-value);
            }
        }
    }
}


/* Bar Chart */
// .barchart{
//     font-size: 11px;
//     line-height: 15px;
//     table-layout: fixed;
//     text-align:center;
//     width: 100%;
//     height:226px;
//     &__axis {
//         border-top: 1px solid $gnr-air;
//         td {
//             text-align: center;
//         }
//     }
// }
// .barchart tr:nth-child(2) td{
//     vertical-align:bottom;
//     height:213px;
// }

// .barchart .ceiling :after {
//     content: "";
//     display: block;
//     width: 100%;
//     border-bottom: 2px dashed $gnr-sunset;
// }

// .barchart .bar{
//     background: $gnr-coral;
//     padding: 0 2px;
//     margin: 0 2px;
//     &--target {
//         background-color: $gnr-sky-light;
//     }
// }

// .barchart .label{
//     margin-top: -30px;
//     text-align: center;
//     width: 100%;
//     display: block;
// }

/* Stacked bar chart */

// .stacked-bar-chart {
//     display: flex;
//     align-items: stretch;
//     margin-bottom: 20px;
//     background: #eee;
//     flex-wrap: wrap;
//     .label {
//         flex: 0 0 150px;
//         font-size: 16px;
//         background: white;
//         // justify-content: center;
//         height: 100px;
//         display: flex;
//     }

//     .bar-container{
//         flex: 1;
//         display: flex;
//         align-items: stretch;
//         justify-content: flex-start;
//         height: 100px;
//         .label {
//             align-items: center;
//         }
//     }

//     .bar {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-size: 16px;
//     }

//     .bar-legend {
//         display: flex;
//         flex-basis: 100%;
//         height: 30px;
//         background: white;
//         .legend {
//             display: flex;
//             align-items: center;
//             font-size: 16px;
//             border-left: 1px solid #ddd;
//             padding-left: 5px;
//             font-size: 12px;
//         }
//     }

//     .val-a { background: salmon }
//     .val-b { background: wheat }
//     .val-c { background: transparent }

// }
