// ------------------------------------------------------------
// Navigation
// ------------------------------------------------------------

// ------------------------------------------------------------
// Primary navigation Toggle
// ------------------------------------------------------------
@use "sass:math";

.navigation-primary-toggle {
	display: none;
	overflow: hidden;
	width: 44px;
	height: 42px;
	position: absolute;
	top: 28px; // Tweak as needed
	right: $internal-spacing*0.5;
	&:before,
	&:after {
		content: "";
		display: block;
		overflow: hidden;
		height: 2px;
		width: 44px;
		background-color: $nav-toggle-color;
		position: absolute;
		top: 12px;
	}
	&:after {
		top: auto;
		bottom: 12px;
	}
	span {
		height: 2px;
		width: 44px;
		background-color: $nav-toggle-color;
		text-indent: -999em;
		display: block;
		position: relative;
	}
	@include respond-to-max($menu-respond) {
		html.js & {
			display: block;
		}
		&--active {
			&:before,
			&:after {
				display: none;
			}
			span {
				background-color: $nav-toggle-color-active;
			}
		}
	}
	@include respond-to($menu-respond) {
		display: none;
		html.js & {
			display: none;
		}
	}
}

// ------------------------------------------------------------
// Primary menu with mobile folding
// ------------------------------------------------------------
.navigation-primary {
	display: block;
	@include respond-to-max($menu-respond) {
		background-color: $gnr-cloud;
		overflow: hidden;
		transition: max-height ease-out 0.35s;
		html.js & {
			//display: none;
			max-height: 0;
		}
		&--active {
			html.js & {
				//display: block;
				transition: max-height ease-out 0.5s;
				max-height: 1000px;
			}
		}
	}
	@include respond-to($menu-respond) {
		position: relative;
		z-index: 15;
		margin-top: -4em;
		&.top {
			// above swup
			z-index: 45;
		}
		html.js & {
			display: block;
		}
		&__items {
			overflow: hidden;
			background-color: $gnr-cloud;
			display: flex;
			width: 100%;
			box-shadow: 0 2px 30px rgba(0,0,0,.15);
		}
	}
	&__item {
		position: relative;
		@include font-size($para);
		@include respond-to-max($menu-respond) {
			&:last-child {
				a {
					border-bottom: none;
				}
			}
		}
		@include respond-to($menu-respond) {
			@include font-size($zeta);
			width: calc(100%/6);
			text-align: center;
			float: left;
			line-height: 1.4;
			position: relative;
		}
		a {
			display: block;
			padding: 1em;
			color: $gnr-slate-a11y;
      		height: 100%;
			span {
				font-size: inherit;
				position: relative;
				z-index: 1;
			}
			@include respond-to-max($menu-respond) {
				padding: 0.75em 0;
				border-bottom: $default-border-width solid $heading-color;
			}
			@include respond-to($menu-respond) {
				&:after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 3px;
					background-color: $action;
					transform: scaleX(0);
					-webkit-transform: scaleX(0);
					transition: all, ease-in-out, 0.3s;
				}
				@include link-active-styles {
					&:after {
						transform: scaleX(1);
						-webkit-transform: scaleX(1);
						transform-origin: bottom left;
						-webkit-transform-origin: bottom left;
					}
				}
			}
		}
		@include respond-to($d-vp + 80px) {
			@include font-size($para);
		}
		&--thin {
			@include respond-to($menu-respond) {
				width: 13.33%;
			}
		}
		&--wide {
			@include respond-to($menu-respond) {
				width: 20%;
			}
		}
		&--active {
			a {
				&:after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 3px;
					background-color: $action;
					transform: scaleX(1);
					-webkit-transform: scaleX(1);
				}
			}
		}
		&--ss-only {
			@include respond-to($menu-respond) {
				display: none;
			}
		}
	}
}

// ------------------------------------------------------------
// Utility navigation
// ------------------------------------------------------------
.navigation-utility {
	@extend %pie-clearfix;
	display: none;
	@include respond-to($menu-respond) {
		display: block;
		text-align: right;
	}
}

.navigation-utility__item {
	@include font-size($zeta);
	display: inline-block;
	margin-left: 2em;
	&:first-child {
		margin-left: 0;
	}
	a {
		color: #fff;
		text-decoration: underline;
	}
}


// ------------------------------------------------------------
// Chapter select navigation
// ------------------------------------------------------------
.navigation-chapter {
	position: relative;
	@include respond-to-max($menu-respond) {
		overflow: hidden;
	}
	@include respond-to($menu-respond) {
		overflow: none;
		position: fixed;
		z-index: 5;
		top: 30em;
		left: 0;
		display: block;
		max-width: 30em;
	}
}

.navigation-chapter__toggle {
	background-color: $gnr-sunset;
	color: #fff;
	@include font-size($eta);
	letter-spacing: 1px;
	padding: 1.7em 1.5em 1.7em 1.5em;
	transition: all, ease-in-out, 0.3s;
	margin-bottom: 1px;
	outline: none;
	width: 100%;
	display: block;
	@include respond-to-max($menu-respond) {
		text-transform: uppercase;
		letter-spacing: 1px;
		&:before {
			content: attr(data-title);
		}
	}
	span {
		height: 2px;
		width: 20px;
		background-color: #fff;
		text-indent: -999em;
		display: block;
		position: absolute;
		right: $internal-spacing*0.5;
		top: 25px;
		margin-left: auto;
		margin-right: auto;
		transition: all, ease-in-out, 0.3s;
		&:after {
			content: "";
			display: block;
			overflow: hidden;
			height: 2px;
			width: 20px;
			background-color: #fff;
			position: absolute;
			top: 0;
			transition: all, ease-in-out, 0.3s;
			transform: rotate(90deg);
			-webkit-transform: rotate(90deg);
		}
	}
	@include respond-to($menu-respond) {
		width: auto;
		padding-top: 2.5em;
		span {
			position: relative;
			margin-bottom: 15px;
			display: block;
			right: auto;
			top: auto;
			width: 25px;
			&:after, &:before {
				content: "";
				display: block;
				overflow: hidden;
				height: 2px;
				width: 25px;
				background-color: #fff;
				position: absolute;
				top: 5px;
				transition: all, ease-in-out, 0.3s;
				transform: rotate(0);
				-webkit-transform: rotate(0);
			}
			&:before {
				top: -5px;
			}
		}
		&:hover {
			background-color: darken($action, 5%);
			span {
				&:after {
					top: 7px;
				}
				&:before {
					top: -7px;
				}
			}
		}
	}
	&--active {
		background-color: darken($action, 5%);
		span {
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
		}
		@include respond-to($menu-respond) {
			span {
				transform: rotate(0);
				background-color: darken($action, 5%);
				&:after {
					top: 0;
					transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
				}
				&:before {
					top: 0;
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
				}
			}
			&:hover {
				span {
					&:after {
						top: 0;
					}
					&:before {
						top: 0;
					}
				}
			}
		}
	}
}

.navigation-chapter__list {
	html.js & {
		max-height: 0;
	}
	&--active {
		html.js & {
			transition: max-height ease-out 0.5s;
			max-height: 1000px;
		}
	}
	@include respond-to($menu-respond) {
		transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
		transition: all, ease-in-out, 0.3s;
		@include respond-to($max-vp) {
			transform: translateX(0);
			-webkit-transform: translateX(0);
		}
		&--active {
			transform: translateX(0);
			-webkit-transform: translateX(0);
		}
	}
}

.navigation-chapter__item {
	position: relative;
	display: block;
	@include font-size($para);
	margin-bottom: 1px;
	a {
		background-color: $gnr-sand;
		color: $heading-color;
		display: block;
		padding: 0.75em 1.5em 0.7em 1em;
		width: 110%;
		transition: all, ease-in-out, 0.3s;
		@extend %font-heading;
		@include link-active-styles {
			background-color: $gnr-sunset;
			color: #fff;
			width: 110%;
		}
		span {
			display: block;
			text-transform: uppercase;
			letter-spacing: 1px;
			@include font-size($eta);
			@extend %font-body;
		}
	}
	&--active a {
		background-color: $gnr-sunset;
		color: #fff;
		width: 110%;
		padding-right: 2rem;
	}
}

.navigation-chapter__child-toggle {
	position: absolute;
	top: 1.5em;
	right: math.div($internal-spacing, 2.5);
	cursor: pointer;
	width: auto;
	height: auto;
	padding: 0;
	transition: all, ease-in-out, 0.3s;
	outline: none;
	@include respond-to($menu-respond) {
		right: 0;
	}
	&--active {
		transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
	}
}

.navigation-chapter__child {
	display: none;
	&--active {
		display: block;
	}
	li {
		a {
			border-top: 1px solid $gnr-sand;
			@extend %font-body;
			@include font-size($zeta);
			transition: all, ease-in-out, 0.3s;
			&:hover {
				text-decoration: underline;
			}
			span {
				@include font-size($zeta);
				display: inline;
				@extend %font-heading;
			}
		}
	}
}

// ------------------------------------------------------------
// burger
// ------------------------------------------------------------
.burger {
	position: relative;
	float: left;
	width: 50px;
	height: 24px;
	margin-left: 15px;
	html.no-js & {
		display: none;
	}
	@include respond-to($menu-respond) {
		margin-left: 25px;
	}
	&:after, &:before, span {
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		height: 2px;
		background: $gnr-slate;
		transform-origin: 0;
		-webkit-transform-origin: 0;
		transition: $transition;
	}
	&:before {
		top: 0;
	}
	span {
		top: 11px;
	}
	&:after {
		bottom: 0;
	}
	&.active {
		span {
			opacity: 0;
		}
		&:before {
			transform: rotate(27deg);
			-webkit-transform: rotate(27deg);
		}
		&:after {
			transform: rotate(-27deg);
			-webkit-transform: rotate(-27deg);
		}
	}
	&.burger--reverse {
		&:after, &:before, span {
			background: #fff;
		}
	}
}
// ------------------------------------------------------------
// chapter and profile nav
// ------------------------------------------------------------

.chapter-nav-wrapper,
.profile-nav-wrapper {
	position: fixed;
	z-index: 20;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background: $gnr-cloud;
	overflow: scroll;
	transition: $transition-slow;
	transform: translateX(100%);
	-webkit-transform: translateX(100%);
	overflow-x: hidden;
	@include respond-to($menu-respond) {
		transform: translateX(0%) translateY(101%);
		-webkit-transform: translateX(0%) translateY(101%);
		height: auto;
		overflow: hidden;
	}
	@media screen and (max-height: 500px) {
		position: fixed;
		height: auto;
		min-height: 100%;
		overflow: scroll;
	}
	&.active {
		transform: translateX(0%);
		-webkit-transform: translateX(0%);
		@include respond-to($menu-respond) {
			transform: translateY(0%);
			-webkit-transform: translateY(0%);
		}
		.chapter-nav,
		.profile-nav {
			opacity: 1;
			transition-delay: 450ms;
		}
	}
	&:after {
		@include respond-to($menu-respond) {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			height: 110%;
			width: 50%;
			background: $gnr-slate;
			transition: $transition-slow;
			transition-delay: 350ms;
			transform: translateX(100%);
			-webkit-transform: translateX(100%);
		}
	}
	&.sub-active {
		&:after {
			transform: translateX(0%);
			-webkit-transform: translateX(0%);
			transition-delay: 0ms;
		}
	}
	html.no-js & {
		padding: 4rem 0;
		position: relative;
		transform: translateX(0%);
		-webkit-transform: translateX(0%);
		transition-delay: 0ms;
		display: none;
		.chapter-nav,
		.profile-nav {
			opacity: 1;
			transition-delay: 450ms;
		}
		&:after {
			transform: translateX(0%);
			-webkit-transform: translateX(0%);
			transition-delay: 0ms;
		}
	}
}

.profile-container {
	@include respond-to($f-vp) {
		position: absolute;
		right: 50px;
		top: 6em;
	}
}


.profile-nav-wrapper {
	right: 0;
	left: auto;
	@include respond-to($menu-respond) {
		width: 40%;
		transform: translateX(101%) translateY(0%);
	}
	&:after {
		@include respond-to($menu-respond) {
			content: none;
		}
	}
	&.active {
		transform: translateX(0%);
	}
	@include respond-to($f-vp) {
		position: inherit;
		width: 300px !important;
		height: auto;
		transition: none;
		transform: translateX(0%) translateY(0%);
		.is-sticky & {
			right: 50px;
		}
	}
}

.chapter-scroll, .profile-scroll {
	@media screen and (max-height: 500px) {
		position: relative;
		height: 100vh;
		//overflow: scroll;
	}
}

.profile-scroll {
	overflow: scroll;
	@include respond-to($f-vp) {
		overflow: auto;
	}
}

.chapter-nav,
.profile-nav {
	position: relative;
	z-index: 100;
	min-height: 100vh;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: $transition;
	transition-delay: 0ms;
	@include clear;
	@media screen and (max-height: 500px) {
		position: fixed;
	    top: 0;
	    left: 0;
	    height: auto;
	    width: 100%;
	}
}

.profile-nav {
	@include respond-to($f-vp) {
		min-height: auto;
		height: auto;
		opacity: 1;
	}
}

.chapter-nav__list,
.profile-nav__list {
	width: 100%;
	height: 100vh;
	overflow: scroll;
	@include respond-to($menu-respond) {
		//height: auto;
		//overflow: inherit;
		overflow-y: scroll;
		position: absolute;
		//left: 5%;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		//width: 50%;
		width: 100%;
		left: 0;
		padding: 5% 0;
		.profile-nav-wrapper & {
			top: 0;
			transform: translateY(0);
			padding: 5% 0;
		}
	}
	@include respond-to($e-vp) {
		//left: 10%;
		//width: 40%;
	}
	@media screen and (max-height: 500px) {
		position: relative;
		overflow: initial;
		top: 0;
		transform: translateY(0);
		-webkit-transform: translateY(0);
	}
	&:hover {
		.chapter-nav__item,
		.profile-nav__item {
			@include respond-to($menu-respond) {
				opacity: 0.5;
				&.active {
					opacity: 1;
				}
			}
			&:hover {
				@include respond-to($menu-respond) {
					opacity: 1;
					&.active {
						opacity: 1;
					}
				}
			}
		}
		.profile-nav__item--heading {
			opacity: 1;
		}
	}
	html.no-js & {
		position: relative;
		left: 0;
		top: 0;
		transform: translateY(0);
		-webkit-transform: translateY(0);
	}
	html.no-cssvhunit & {
		padding-top: 0;
		top: 3em;
		transform: translateY(0);
		-webkit-transform: translateY(0);
	}
	.profile-nav-wrapper & {
		@include respond-to($menu-respond) {
			left: 0;
			width: 100%;
		}
	}
}

.profile-nav__list {
	@include respond-to($f-vp) {
		overflow-y: auto;
		overflow: auto;
		position: relative;
		left: 0;
		top: 0%;
		transform: translateY(0);
		-webkit-transform: translateY(0);
		width: auto;
		left: 0;
		padding: 0;
		height: auto !important;
		.profile-nav-wrapper & {
			padding: 0;
		}
	}
}

.chapter-nav__item,
.profile-nav__item {
	position: relative;
	transition: $transition;
	border-bottom: 1px solid rgba($gnr-slate, 0.5);
	@include respond-to($menu-respond) {
		position: static;
		position: initial;
		border-bottom: 0;
	}
	.chapter-title {
		display: block;
		opacity: 1;
		html.no-js & {
			padding-top: 2rem;
			border-top: $border-width solid $gnr-slate;
		}
	}
	&:last-of-type {
		margin-bottom: 65px;
		@include respond-to($menu-respond) {
			margin-bottom: 0;
		}
		.chapter-nav__child {
			padding-bottom: 75px;
			@include respond-to($menu-respond) {
				padding-bottom: 0;
			}
		}
	}
}

.profile-nav__item--heading {
	@include respond-to($f-vp) {
		background-color: $action;
		.profile-nav-link .heading {
			color: #fff !important;
			margin-bottom: 0;
		}
	}
}

.chapter-nav-link,
.profile-nav-link {
	position: relative;
	display: block;
	color: $gnr-slate;
	padding: 2rem;
	@include respond-to($menu-respond) {
		padding: 2rem 15%;
		.profile-nav-wrapper & {
			padding: 1rem 20%;
		}
	}
	&:hover, &:active, &:visited {
		color: $gnr-slate;
	}
	&:after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 30px;
		height: 100%;
		z-index: -1;
	}
	.sub-active & {
		&:after {
			content: '';
			height: 100%;
			background: $gnr-cloud;
			transition: $transition-slow;
			transform: translateX(30px);
			transition-delay: 00ms;
			html.no-js & {
				display: none;
			}
			right: 60%;
		}
		&.active {
			&:after {
				transform: translateX(30px);
				transition-delay: 0;
				@include respond-to($menu-respond) {
					right: 50%;
				}
			}
		}
	}
	html.no-js & {
		padding: 0;
	}
}

.profile-nav-link {
	@include respond-to($f-vp) {
		padding: 1em;
		.profile-nav-wrapper & {
			padding: 1em 2em;
		}
	}
}

.chapter-nav__title,
.profile-nav__title {
	display: block;
	@include font-size($zeta);
	@extend %font-heading;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.chapter-nav__title {
	max-width: 90%;
	@include respond-to($c-vp) {
		@include font-size($delta);
		max-width: 40%;
	}
}

.chapter-nav__child-toggle {
	position: absolute;
	top: 0;
	right: 0;
	background: $gnr-slate;
	height: 100%;
	width: 40px;
	@include respond-to($menu-respond) {
		display: none;
	}
	.ico {
		position: absolute;
		left: 50%;
		top: 41px;
		transform: rotate(0deg) translateY(-50%) translateX(-50%);
		-webkit-transform: rotate(0deg) translateY(-50%) translateX(-50%);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		transition: $transition;
	}
	&.active {
		.ico {
			transform: rotate(180deg) translateY(-50%) translateX(-50%);
			-webkit-transform: rotate(180deg) translateY(-50%) translateX(-50%);
		}
	}
	&:focus {
		outline: none;
	}
}

// child ======================================================
.chapter-nav__child {
	display: none;
	background: $gnr-slate;
	padding: 1em 0;
	//
	@include respond-to($menu-respond) {
		padding: 2rem 0;
		background: transparent;
		display: block;
		padding-bottom: 0;
		position: absolute;
		z-index: 10;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transition: $transition;
		transition-delay: 0ms;
		max-height: calc(100vh - 4rem);
		opacity: 0;
		pointer-events: none;
		//left: 100%;
		left: 50%;
		width: 50%;
	}
	@media screen and (max-height: 500px) {
		top: 0;
		transform: translateY(0%);
		-webkit-transform: translateY(0%);
	}
	&::-webkit-scrollbar {
	    display: none;
	}
	&.active {
		display: block;
		@include respond-to($menu-respond) {
			transition-delay: 350ms;
			opacity: 1;
			pointer-events: auto;
		}
	}
	&:hover {
		.chapter-nav-link--child {
			opacity: 0.5;
			&:hover {
				opacity: 1;
			}
		}
	}
	html.no-js & {
		opacity: 1;
		position: relative;
		left: 0;
		top: 0;
		transform: translateY(0);
		-webkit-transform: translateY(0);
		pointer-events: auto;
	}
	html.no-cssvhunit & {
		top: 1rem;
		transform: translateY(0);
		-webkit-transform: translateY(0);
	}
}

.chapter-nav__child__overflow {
	@media screen and (max-height: 500px) {
		//position: fixed;
		overflow-y: scroll;
		height: auto;
		max-height: 400px;
	}
	@include respond-to($menu-respond) {
		//position: fixed;
		overflow-y: scroll;
		height: 100vh;
		max-height: 450px;
		// --
		padding: 0 15%;
		margin-right: 15%;
		scrollbar-color: $gnr-cloud $gnr-slate;
		&::-webkit-scrollbar {
		    -webkit-appearance: none;
		    width: 7px;
		}
		&::-webkit-scrollbar-track {
		    background-color: none;
		}
		&::-webkit-scrollbar-thumb {
		    border-radius: 4px;
		    background-color: $gnr-cloud;
		    box-shadow: 0 0 1px rgba(255,255,255,.5);
		}
	}
	html.no-js & {
		height: 250px;
		padding-left: 0%;
		margin-bottom: 5rem;
	}
}

.chapter-nav-link--child {
	display: block;
	padding: 1rem 2rem;
	@include transition(opacity);
	@include respond-to($menu-respond) {
		padding: 1.5rem 0 1.5rem;
	}
	&:first-of-type {
		padding-top: 0;
	}
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.chapter-sub-title {
	color: $gnr-cloud;
	border-bottom: 3px solid $gnr-cloud;
	margin-bottom: 1rem;
	padding-right: 30px;
	@include font-size($eta);
	@include respond-to($c-vp) {
		@include font-size(math.div($zeta, 1.25));
	}
	html.no-js & {
		color: $gnr-slate;
	}
}

.chapter-nav__title--child {
	@include font-size($zeta);
	display: block;
	color: $gnr-cloud;
	@include respond-to($menu-respond) {
		// can't use extend in media query
		font-family: "Averta W01 Bold", sans-serif;
	}
	html.no-js & {
		color: $gnr-slate;
	}
}

.chapter-nav__button {
	display: none;
	@include respond-to($menu-respond) {
		display: block;
		transition: $transition;
		margin-bottom: 2rem;
		// --
		//margin-left: 15%;
		margin-left: 5%;
	}
	.button {
		margin-left: 2rem;
		@include respond-to($menu-respond) {
			margin-left: 15%;
		}
		margin-bottom: 1em;
		&:hover {
			background: $action;
		}
	}
	&.active {
		display: block;
		transition-delay: 350ms;
		opacity: 1;
		pointer-events: auto;
	}
	html.no-js & {
		margin-left: 0%;
		a {
			color: $gnr-slate;
			border: 1px solid $gnr-slate;
			&:hover {
				color: #fff;
				border-color: $action;
			}
		}
	}
}

// secondary navigation ---------------------------------------
.l-sidebar__aside {
	margin-bottom: 2rem;
	@include respond-to($d-vp) {
		margin-bottom: 0;
		margin-top: 2.5rem;
	}
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		@include respond-to($d-vp) {
		    top: -2rem;
		    left: 2rem;
		    width: 100%;
		    height: 100%;
		    background-size: 40%;
		    background-image: url(../img/coral-dots.png);
		}
		html.svg & {
			background-size: 7%;
			background-image: url(../svg/source/coral-dots-02.svg);
		}
	}
}

.inPageNav {
	position: relative;
	width: 100%;
	background: $action;
	display: inline-block;
	@include respond-to($d-vp) {
		margin-bottom: 0;
	}
}

.inPageNav__item {
	position: relative;
	@extend .heading;
	@include font-size($epsilon);
	border-bottom: 1px solid rgba($body-color, 0.5);
	a {
		color: $body-color;
		padding: 1rem 2rem;
		display: block;
	}
	&:last-of-type {
		border-bottom: 0;
	}
	&:hover {

	}
    ul {
        .inPageNav__item {
            background-color: lighten($action,5%);
            a {
                padding-left: 3em;
            }
        }
    }
}

.inPageNav__link {
	&:after {
		content: '';
		position: absolute;
		left: -2.5rem;
		top: 11px;
		height: 22px;
		width: $border-width;
		background: $body-color;
		transition: $transition;
	}
	.hero__aside & {
		&:after {
			content: none;
		}
	}
	&:hover {
		&:after {
			left: 0;
		}
		.hero__aside & {
			background: $action-respond;
		}
	}
}

a.inPageNav__active {
	padding-left: 3rem;
	&:after {
		left: 1rem;
	}
	.hero__aside & {
		padding-left: 2rem;
		background: $action-respond;
		&:after {
			content: none;
		}
	}
	&:hover {
		&:after {
			left: 1rem;
		}
	}
   .inPageNav ul &:after {
        left: 2.4rem;
    }
    .hero--goal & {
        background: $gnr-slate;
    }
}
