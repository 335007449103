// -----------------------------------------
// Include our old grid if you need it
// -----------------------------------------
//@include grid;
//@include grid__row;

// -----------------------------------------

.row {
	@extend %pie-clearfix;
	min-width: auto;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: $max-grid;
	padding-left: $internal-spacing*0.25;
	padding-right: $internal-spacing*0.25;
	@include respond-to($menu-respond) {
		padding-left: $internal-spacing;
		padding-right: $internal-spacing;
	}
}

.row__nojs {
	html.no-js & {
		@extend %pie-clearfix;
		min-width: auto;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		max-width: $max-grid;
		padding-left: $internal-spacing*0.25;
		padding-right: $internal-spacing*0.25;
		@include respond-to($menu-respond) {
			padding-left: $internal-spacing;
			padding-right: $internal-spacing;
		}
	}
}

.row-smler {
	max-width: 1000px;
	margin: 0 auto;
}
