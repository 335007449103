// ------------------------------------------------------------
// Quantity Block
// ------------------------------------------------------------

.qty-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: $internal-spacing*.5 0;
    @include respond-to(560px) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: $internal-spacing*.5;
    }
}

.qty-block {
    min-height: 96px;
    padding: 22px 10px 22px 48px;
    width: 100%;
    position: relative;
    margin-bottom: $internal-spacing*.25;
    background: $gnr-slate;
    @include respond-to(560px) {
        width: 250px;
        min-height: 150px;
        margin-bottom: 0;
        padding: 24px 20px 24px 48px;

    }
    &:before {
        position: absolute;
        top: 24px;
        bottom: 24px;
        left: 20px;
        content: "";
        width: 10px;
        background-color: #fff;
    }
}

.qty-block__value {
    margin-top: 3px;
    @extend %font-heading;
    @include font-size(64);
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
    @include respond-to(560px) {
        margin-top: 1px;
    }
}

.qty-block__description {
    padding-left: 10px;
    margin-top: 18px;
    @include font-size($para);
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}
