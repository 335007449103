// ------------------------------------------------------------
// Header
// ------------------------------------------------------------

.header {
	@extend %pie-clearfix;
	position: relative;
	z-index: 10;
	background-color: $gnr-slate;
	.row {
		position: relative;
		padding-top: 2em;
		padding-bottom: 2em;
		@include respond-to($menu-respond) {
			padding-bottom: 6em;
		}
	}
	&.top {
		// above swup
		z-index: 35;
	}
}

.header__utl {
	display: block;
	@include respond-to($menu-respond) {
		float: right;
		width: 70%;
	}
	@include respond-to($d-vp) {
		width: 50%;
	}
}
