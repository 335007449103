// ------------------------------------------------------------
// Background control
// If you BEM, this helps keep it dry(er)
// ------------------------------------------------------------

.background-cover {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.background-contain {
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}

.background-anchor-center-top {
	background-position: center top;
}

.background-anchor-center-bottom {
	background-position: center bottom;
}

.background-anchor-center-left {
	background-position: center left;
}

.background-anchor-center-right {
	background-position: center right;
}