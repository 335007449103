// ------------------------------------------------------------
// Filter
// ------------------------------------------------------------

.filter {
    background-color: $gnr-cloud;
    padding: 2em;
    display: none;
    &--active {
        display: block;
    }
    @include respond-to($sidebar-respond) {
        display: block;
    }
    .no-js & {
        display: block;
    }
    &--paddless {
        padding: 0 0 2em 0;
        > .filter__list,
        > .filter-group,
        > .form-field {
            padding-left: 2em;
            padding-right: 2em;
        }
        > .aside__heading {
            padding: 1em;
            margin-bottom: 0;
        }
    }
}

.filter-trigger {
    @extend %font-heading;
    @include font-size($delta);
    background-color: $gnr-slate;
    display: block;
    color: #fff;
    width: 100%;
    padding: 0.75em 1em;
    .ico {
        transition: all, ease-in-out, 0.3s;
        margin-right: 10px;
    }
    @include respond-to($sidebar-respond) {
        display: none;
    }
    &--active {
        .ico {
            transform: rotate(180deg);
        }
    }
    .no-js & {
        display: none;
    }
}

.filter__list {
    margin-top: 15px;
    li {
        margin-bottom: 0;
        &:last-child {
            margin-bottom: 0;
        }
        .form-checkbox {
            margin-right: 10px;
        }
        ul {
            margin-left: 2.5em;
            li {
                margin-bottom: 0;
            }
        }
    }
    .form-label {
        font-weight: 400;
    }
    > li > label.form-label,
    .form-label--parent {
        font-weight: 700;
    }
    .filter-drawer &{
        margin-top: 0;
    }
}

.filter-group {
    margin-top: 2em;
    .button {
        display: inline-block;
        width: auto;
        background-color: $action;
        border: 1px solid $action;
        color: #fff;
        margin-right: 5px;
        margin-bottom: 5px;
        &:hover {
            background-color: $action-respond;
            border-color: $action-respond;
        }
        + .button {
            background-color: $action-respond;
            border: 1px solid $action-respond;
            margin-top: 0;
            &:hover {
                background-color: $action;
                border-color: $action;
            }
        }
    }
}
