// ------------------------------------------------------------
// Cards
// ------------------------------------------------------------


@use "sass:math";

.card {
    display: block;
    background: $gnr-slate;
    padding: 3rem 2rem 2rem;
    .button {
        margin-top: 1.5em;
    }
    // with card
    &-imageCard {
        background: transparent;
        padding: 0;
        .card__headng {
            position: relative;
            margin-top: 0;
            display: inline-block;
            padding-top: 1rem;
                &:after, &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: $border-width;
                    background: #fff;
                    transition: $transition;
                    transition-delay: 100ms;
                }
                &:after {
                    width: 0;
                    background: $action;
                }
        }
        &:hover {
            .card__headng {
                &:after {
                    width: 100%;
                    right: auto;
                    left: 0;
                }
            }
        }
    }
    // end with card
    &:hover {
		cursor: pointer;
		.button {
			background: $action;
			border-color: $action;
		}
		.card__meta, .card__heading, .card__anchor {
			&:after {
				width: 100%;
				right: auto;
				left: 0;
			}
		}
	}
}

.card__anchor {
    position: relative;
    width: 3em;
    height: $border-width;
    background: #fff;
    display: block;
    &:after {
        position: absolute;
		top: 0;
		right: 0;
        width: 100%;
        background: $action;
        transition: $transition;
    }
}

.card__meta {
    display: inline-block;
	position: relative;
	color: $body-color;
    padding-top: 1rem;
    @include small-caps;
	@include font-size(math.div($eta, 1.25));
    &:after, &:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: $border-width;
		background: #fff;
		transition: $transition;
		transition-delay: 100ms;
	}
	&:after {
		width: 0;
		background: $action;
	}
}

.card__headng {
    @extend .type-m;
    @extend .heading;
    margin-top: 1em;
    color: #fff;
    .card--reverse & {
        color: $heading-color;
    }
}

.card__excerpt {
    color: $body-color;
	@include font-size(math.div($delta, 1.25));
    margin-top: 1em;
    .card--reverse & {
        color: $base-color;
    }
}

.card-content {
    background: $gnr-slate;
    padding: 2rem;
    .card--reverse & {
        background: #fff;
        padding-left: 0;
        padding-right: 0;
    }
}

.card-image {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 150px;
    width: 100%;
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: $gnr-slate;
        background-image: url(../img/aqua-dots-01.png);
        background-size: 10%;
        html.svg & {
            background-image: url(../svg/source/aqua-dots-02.svg);
        }
    }
}
