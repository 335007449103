
.autocomplete {
    font-size: 16px;
    background: white;
    z-index: 1000;
    overflow: auto;
    box-sizing: border-box;
    border: 1px solid rgba(50, 50, 50, 0.6);
    box-shadow: 0 20px 30px rgba(0,0,0,.25);
    a {
        color: $gnr-slate;
        transition: none;
    }
}

.autocomplete * {
    font: inherit;
}

.autocomplete > div {
    padding: 4px 10px;
}

.autocomplete > div.selected {
    background: $gnr-slate;
    font-weight: bold;
    cursor: pointer;
    a {
        color: #fff;
    }
}

.autocomplete > div:hover:not(.selected) {
    cursor: pointer;
    background: lighten($gnr-sky-light, 10%);
    a {
        color: $gnr-slate;
    }
}

