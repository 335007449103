// ------------------------------------------------------------
// Sections
// Relates to the spacing and padding of whole sections
// ------------------------------------------------------------

// Padding ----------------------------------------------------
@use "sass:math";

.padd-section-top {
	padding-top: $internal-spacing-sml;
	@include respond-to($c-vp) {
		padding-top: $internal-spacing;
	}
}

.padd-section-bottom {
	padding-bottom: $internal-spacing-sml;
	@include respond-to($c-vp) {
		padding-bottom: $internal-spacing;
	}
}

.padd-section-both {
	padding-top: $internal-spacing-sml;
	padding-bottom: $internal-spacing-sml;
	@include respond-to($c-vp) {
		padding-top: $internal-spacing;
		padding-bottom: $internal-spacing;
	}
}

// sml
.padd-section-top-sml {
	padding-top: math.div($internal-spacing, 3);
	@include respond-to($c-vp) {
		padding-top: $internal-spacing * 0.5;
	}
}

.padd-section-bottom-sml {
	padding-bottom: math.div($internal-spacing, 3);
	@include respond-to($c-vp) {
		padding-bottom: $internal-spacing;
	}
}

.padd-section-both-sml {
	padding-bottom: math.div($internal-spacing, 3);
	padding-top: math.div($internal-spacing, 3);
	@include respond-to($c-vp) {
		padding-bottom: $internal-spacing-sml;
		padding-top: $internal-spacing-sml;
	}
}

// lrge
.padd-section-top-lrg {
	padding-top: $internal-spacing * 0.5;
	@include respond-to($c-vp) {
		padding-top: $internal-spacing * 1.25;
	}
}


// Spacing ----------------------------------------------------
.space-section-top {
	margin-top: $internal-spacing-sml;
	@include respond-to($c-vp) {
		margin-top: $internal-spacing;
	}
}

.space-section-top-large {
	margin-top: $internal-spacing-sml * 1.75;
	@include respond-to($c-vp) {
		margin-top: $internal-spacing * 1.75;
	}
}

.space-section-top-sml { 
	margin-top: math.div($internal-spacing-sml, 3);
	@include respond-to($c-vp) {
		margin-top: math.div($internal-spacing, 3);
	}
}

.space-section-bottom {
	margin-bottom: $internal-spacing-sml;
	@include respond-to($c-vp) {
		margin-bottom: $internal-spacing;
	}
}


.space-section-both {
	margin-top: $internal-spacing-sml;
	margin-bottom: $internal-spacing-sml;
	@include respond-to($c-vp) {
		margin-top: $internal-spacing;
		margin-bottom: $internal-spacing;
	}
}

// medium
.space-section-top-med {
	margin-top: 5rem;
	@include respond-to($c-vp) {
		margin-top: 6rem;
	}
}

.space-section-bottom-med {
	margin-bottom: 5rem;
	@include respond-to($c-vp) {
		margin-bottom: 6rem;
	}
}

.space-section-both-med {
	margin-bottom: 5rem;
	margin-top: 5rem;
	@include respond-to($c-vp) {
		margin-bottom: 6rem;
		margin-top: 6rem;
	}
}

// large
.space-section-bottom-large {
	margin-bottom: $internal-spacing * 1.75;
}

.space-section-both-large {
	margin-bottom: 5rem;
	margin-top: 5rem;
	@include respond-to($c-vp) {
		margin-bottom: $internal-spacing * 1.75;
		margin-top: $internal-spacing * 1.75;
	}
}
