// -----------------------------------------------------------
// Add brand items such as logos here
// -----------------------------------------------------------

.branding {
    background-image: url(/assets/svg/source/logo.svg);
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    display: block;
    text-indent: -999em;
    @include respond-to($menu-respond) {
        width: 100px;
        height: 100px;
        float: left;
    }
}
