// accessible hide
//  source: https://webaim.org/techniques/css/invisiblecontent/
//  and: https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
@use "sass:math";

%accessible-hide {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

// ---------------------------------------
// Table styles
// ---------------------------------------

.table-styled--numeric {
	tbody tr {
		td {
			text-align: right;
		}
	}
}

.is-typeset table,
.table-styled,
.table-styled table {
	@include font-size($zeta);
	width: 100%;
	@include respond-to-max($c-vp) {
		border-collapse: collapse;
		overflow-x: auto;
		display: block;
		width: 100%;
        th {
            white-space: wrap !important;
        }
	}
	th {
		background-color: $gnr-cloud;
		// border: 1px solid #fff;
		// color: #fff;
		padding: 1em;
        &[scope=col] {
            vertical-align: bottom;
        }
        &[scope=row] {
            vertical-align: top;
        }
        border-top: 1px solid $default-border-color;
        white-space: nowrap;
	}
	td {
		padding: 1em;
		vertical-align: middle;
        ul {
            margin-top: 1rem;
            margin-bottom: 0;
            padding-left: 2rem;
        }
	}
	tbody tr {
		border-bottom: 1px solid $default-border-color;
        &:first-child {
            border-top: $default-border-width solid $default-border-color;
        }
	}
}

.table-styled--headless {
    tbody tr:first-of-type {
        border-top: 1px solid $default-border-color;
    }
}

.is-typeset table, .is-typeset .table-styled {
	margin-bottom: 2em;
}

.table-responsive {
	width: 100%;
	border-top: $default-border-width solid $default-border-color;
	td {
		position: relative;
		line-height: 1.4;
		vertical-align: top;
	}
	@include respond-to-max($table-respond) {
		border-collapse: separate;
		table, thead, tbody, th, td, tr {
			display: block;
		}
		thead, th {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		tr {
			margin-bottom: $internal-spacing*0.5;
			border-top: $default-border-width solid $default-border-color;
			overflow: hidden;
			&:first-child {
				border-top: none;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		td {
			position: relative;
			background: #fff;
			border-bottom: $default-border-width solid $default-border-color;
			border-right: $default-border-width solid $default-border-color;
			border-left: $default-border-width solid $default-border-color;
			padding-left: 0;
			vertical-align: top;
			padding: $internal-spacing*0.25 0;
			padding-left: 45%;
			&:before {
				position: relative;
				display: inline-block;
				width: 45%;
				word-break: break-word;
				content: attr(title);
				padding: $internal-spacing*0.25 $internal-spacing*0.5;
				margin-right: $internal-spacing-percent*1.5;
				color: $heading-color;
				font-weight: 700;
				position: absolute;
				top: $internal-spacing-pixel*0.25;
				left: 0;
			}
		}
	}
	@include respond-to($table-respond) {
		border: none;
		th, td {
			border-right: $default-border-width solid $default-border-color;
			&:last-child {
				border-right: none;
			}
		}
		thead {
			th {
				padding: math.div($internal-spacing, 3) $internal-spacing*0.5;
			}
		}
		tbody {
			tr {
				background: #fff;
				border-bottom: $default-border-width solid $default-border-color;
				&:nth-child(even) {

				}
			}
			td {
				padding: $internal-spacing*0.25 $internal-spacing*0.5;
			}
		}
	}
}

.table-profile {
	margin: 1em 0;
	@include font-size($para);
	border-top: 1px solid $gnr-slate;
	border-bottom: 1px solid $gnr-slate;
	width: 100%;
	thead {
		@extend %font-heading;
		border-bottom: 1px solid $gnr-slate;
		width: 100%;
	}
	tbody tr {
		border-bottom: 1px solid $gnr-cloud;
		&:last-child {
			border-bottom: none;
		}
	}
	th {
		padding: 0.75em;
	}
	td {
		padding: 0.75em;
		&.table-profile__off-course {
			color: #fff;
			background-color: $gnr-sunrise;
		}
		&.table-profile__on-course {
			color: $heading-color;
			background-color: $gnr-sky-light;
		}
		&.table-profile__reached {
			color: $heading-color;
			background-color: $gnr-aqua;
		}
		&.table-profile__unknown,
		&.table-profile__undetermined {
			color: $heading-color;
			background-color: $gnr-cloud;
		}
	}
	.profile-figure & {
		@extend %accessible-hide;
	}
}

.table-profile__divider {
	border-color: $gnr-slate !important;
}

.table-styled__note {
    @include respond-to($table-respond) {
       float: right;
    }
}
