// ------------------------------------------------------------
// Colours and fills
// Add more to the array to populate consistent names across
// colors, fills (aka background) and rules (aka borders)
// ------------------------------------------------------------

@each $name, $color in
		('sunset', $gnr-sunset),
		('sunrise', $gnr-sunrise),
		('sand', $gnr-sand),
		('coral', $gnr-coral),
		('aqua', $gnr-aqua),
		('sky', $gnr-sky),
		('sky-light', $gnr-sky-light),
		('slate', $gnr-slate),
		('concrete', $gnr-concrete),
		('cloud', $gnr-cloud),
		('cloud-light', lighten($gnr-cloud, 5%))
	{
	.color-#{$name} {
		color: $color;
	}
	.fill-#{$name} {
		background-color: $color;
		position: relative;
		z-index: 1;
	}
// ------------------------------------------------------------
// For Pattern library colour swatches. Remove if this isn't include in a project
// If usings swatches, the names in the 'ptn-colour.json' file must match up with the defined colours above
// ------------------------------------------------------------
	.swatch-#{$name} {
		.swatch {
			background-color: $color;
		}
		.swatch-color {
			display: block;
			&:after {
				content: "#{$color}";
				display: block;
			}
		}
	}
}
