// ------------------------------------------------------------
// Wagtail has it's own set of fringe items
// ------------------------------------------------------------

.wysiwyg {
	%wagtail-image-align {
		max-width: 500px;
		width: 100%;
		display: block;
		vertical-align: top;
		position: relative;
	}
	
	img.full-width {
		
	}
	
	img.left {
		@extend %wagtail-image-align;
		@include respond-to($menu-respond) {
			margin-right: 40px;
			float: left;
		}
	}
	
	img.right {
		@extend %wagtail-image-align;
		@include respond-to($menu-respond) {
			margin-left: 40px;
			float: right;
		}
	}
}