// ------------------------------------------------------------
// commitment
// ------------------------------------------------------------

@use "sass:math";

.commitment__item {
	background-color: $gnr-slate;
    margin-bottom: 2rem;
}

.commitment__wrap {
	@extend %pie-clearfix;
	display: block;
    position: relative;
	@include respond-to($c-vp) {
		width: 100%;
        @supports (display: grid) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        }
	}
}

.commitment__content {
	padding: 2rem;
    display: block;
	@include respond-to($c-vp) {
		width: 70%;
		float: left;
        @supports (display: grid) {
            grid-column: 5/span 8;
            grid-row: 1;
            width: auto;
            float: none;
        }
	}
    &:hover {
        .meta__date {
            &:after {
                @include respond-to($c-vp) {
                    right: auto;
                    left: 0;
                    width: 100%;
                }
            }
        }
    }
}

.commitment__heading {
	color: $body-color;
	margin: 0.5rem 0;
    @include font-size($gamma);
    @extend %font-heading;
    line-height: 1.3;
	@include respond-to($c-vp) {
		margin: 1rem 0;
	}
}

.commitment__aside {
    color: $body-color;
    background-color: $gnr-slate-a11y;
    padding: 2rem;
    display: block;
    @include respond-to($c-vp) {
        width: 30%;
        float: right;
        height: 100%;
        @supports (display: grid) {
            grid-column: 1/span 4;
            grid-row: 1;
            width: auto;
            float: none;
        }
    }
    transition: all ease-in-out 0.1s;
}
a.commitment__aside {
    &:hover, &:focus {
        background-color: $gnr-sunset-a11y;
    }
}

.commitment__aside__heading {
    color: $body-color;
    margin-bottom: 1rem;
}

.commitment__list {
	color: $body-color;
	@include font-size(math.div($delta, 1.25));
	li {
		margin-bottom: 0.25em;
		&:last-child {
			margin-bottom: 0;
		}
		span {
			font-weight: bold;
		}
        svg {
            width: 18px;
            height: 18px;
            vertical-align: text-bottom;
            margin-right: 5px;
        }
	}
}


.commitment__drawer {
    .drawer {
        margin-top: 0;
        margin-bottom: 0;
        &[open] .drawer__title {
            background-color: $gnr-air;
            color: $heading-color;
            &::before {
                background-image: url(/assets/svg/source/drop-blue.svg);
            }
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .drawer__title {
        border-radius: 0;
    }
    .drawer__content {
        padding: 0;
    }
}
