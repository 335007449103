// ------------------------------------------------------------
// Button styles
// ------------------------------------------------------------

@use "sass:math";

.button {
	background: transparent; // // Reset button and reset elements
	border: none; // Reset button and reset elements
	// ------- //
	display: inline-block;
	text-align: left;
	padding: 1.5rem 1.25rem;
	color: $action;
	background-color: transparent;
	border: 1px solid $action;
	text-decoration: none;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	transition: $transition;
	text-transform: uppercase;
	letter-spacing: 1px;
	@extend .heading;
	@include font-size(math.div($eta, 1.25));
	@include link-active-styles {
		color: #fff;
		background-color: $action;
	}
	@include respond-to($c-vp) {
		padding: 1.5rem 1.75rem;
	}
	.ico {
		position: relative;
		top: -1px;
		margin-left: 10px;
		.chapter-nav__list & {
			margin-left: 0;
			margin-right: 5px;
		}
	}
	&.button--sunrise {
		border-color: $gnr-sunrise;
		color: $gnr-sunrise;
		&:hover {
			color: $body-color;
			background: $gnr-sunrise;
		}
	}
	&.button--summary {
		color: $gnr-slate;
		border-color: $gnr-slate;
		&:hover {
			color: #fff;
			background: $gnr-slate;
		}
		.chapter-nav__list & {
			margin-top: 2em;
			margin-left: 2em;
			.ico {
				background-image: url(../svg/source/list-slate.svg);
				background-size: contain;
				background-repeat: no-repeat;
				width: 16px;
				height: 16px;
			}
			&:hover {
				.ico {
					background-image: url(../svg/source/list-white.svg);
				}
			}
			@include respond-to($menu-respond) {
				margin-left: 15%;
				margin-top: 0;
			}
		}
	}
	&[data-js-reveal-trigger] {
		.no-js & {
			display: none;
		}
	}
}

.button + .button {
	margin: 1rem 0 0 0;
	@include respond-to($a-vp) {
		margin: 0 0 0 0;
	}
	@include respond-to($b-vp) {
		margin: 0 2rem 0 0;
	}
}

.button-sml {
	padding: 1rem 1.25rem;
	@include respond-to($c-vp) {
		padding: 1rem 1.75rem;
	}
}

.button--inline {
    padding: .625rem 1.25rem;
    vertical-align: middle;
    @include respond-to($c-vp) {
        padding: .625rem 1.25rem;
    }
}

.hero .button--inline {
    margin-top: -5px!important;
    margin-left: 5px;
}

// Give actual buttons some love
// button.button {
// 	border: 0;
// 	width: 100%;
// }


// ------------------------------------------------------------
// Accessible labels
// ------------------------------------------------------------

.button-a11y {
	position: relative;
	overflow: hidden;
	span {
		top: -999em;
		position: absolute;
		display: inline-block;
	}
	&:after {
		 content: attr(title);
		 display: inline-block;
		 color: inherit;
	}
}

// We want this available as a BEM to extend .button, as well as being stand alone
.button--a11y {
	@extend .button-a11y;
}

// ------------------------------------------------------------
// Variation styles
// ------------------------------------------------------------

.button--block {
	display: block;
	width: 100%;
}

.button--secondary {
	border-color: $action-respond;
	color: $action-respond;
	@include link-active-styles {
		background-color: $action-respond;
		color: #fff;
	}
}

.button--tertiary {
    border-color: #fff;
    color: #fff;
    @include link-active-styles {
        background-color: #fff;
        color: $base-color;
    }
}

.button--disabled {
	opacity: 0.5;
	cursor: not-allowed;
	filter: grayscale(100%);
}

// ------------------------------------------------------------
// Download button
// ------------------------------------------------------------
.download-button {
	position: relative;
	padding: 1.5rem 1.75rem 1.3rem;
	ul {
		position: absolute;
		z-index: 100;
		zoom: 1;
		left: -1px;
		top: calc(100% + 1px);
		width: calc(100% + 2px);
		background: #fff;
		border: 1px solid $gnr-slate;
		border-top: 0;
		// transform: translateY(50px);
		transition: $transition;
		pointer-events: none;
		opacity: 0;
		html.no-js & {
			opacity: 1;
			transform: translateY(0);
			-webkit-transform: translateY(0);
			pointer-events: auto;
		}
	}
	li {
		color: $gnr-slate;
		padding: 1rem 1.5rem;
		a {
			color: $gnr-slate;
			display: block;
			&:hover {
				color: $action;
				span {
					&:after {
						background: $action;
					}
				}
			}
		}
	}
	&:hover, &:focus, &:focus-within {
		ul {
			opacity: 1;
			transform: translateY(0);
			-webkit-transform: translateY(0);
			pointer-events: auto;
		}
	}
	// ie
	&:hover {
		ul {
			opacity: 1;
			transform: translateY(0);
			-webkit-transform: translateY(0);
			pointer-events: auto;
		}
	}
	&.show {
		ul {
			opacity: 1;
			transform: translateY(0);
			-webkit-transform: translateY(0);
			pointer-events: auto;
		}
	}
}

.downloadSize {
	position: relative;
	margin-left: 5px;
	padding-left: 8px;
	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 5%;
		height: 90%;
		width: 1px;
		background: rgba($gnr-slate, 0.75);
		transition: $transition;
	}
}

.download-button--reverse {
    margin-top: 2.5rem;
    border-color: #fff;
    color: #fff;
    background-blend-mode: multiply;
    ul {
    	border-color: $action;
    }
    &:hover {
        border-color: $action;
        background: $action;
    }
}

.download-button--white {
	background: #fff;
	color: $gnr-slate;
	border-color: #fff;
	ul {
		border-color: $action;
	}
	&:hover {
		border-color: $action;
	}
}

.download-button--action {
	background: $action;
	color: #fff;
	border-color: #action;
	ul {
		border-color: $action;
	}
	&:hover {
		border-color: $action;
	}
}

.button--reverse {
	border-color: #fff;
	color: #fff;
	&:hover {
	    border-color: $action;
	    background: $action;
	}
}

.button-full-white {
	background: #fff;
	color: $gnr-slate;
	border-color: #fff;
	&:hover {
		border-color: $action;
		background: $action;
	}
}

.button-full-action {
	background: $action;
	color: #fff;
	border-color: $action;
	&:hover {
		border-color: $action-respond;
		background: $action-respond;
	}
}

// ------------------------------------------------------------
// button alternatives
// ------------------------------------------------------------
.button-slate {
	border-color: $gnr-slate;
	color: $gnr-slate;
    background-color: transparent;
	&:hover {
		background: $gnr-slate;
	}
}
