// ------------------------------------------------------------
// Highlights
// ------------------------------------------------------------

.highlight {
    background-color: $gnr-slate;
    padding: 3em;
    margin-bottom: 2em;
}

.highlight__flag {
    background-color: #fff;
    @include font-size($zeta);
    padding: 0.25em 0.5em;
    display: inline-block;
    margin-bottom: 1em;

}

.highlight__list {
    @include font-size($para);
    color: #fff;
    li {
        margin-bottom: 0.25em;
        &:last-child {
            margin-bottom: 0;
        }
        span {
            font-weight: bold;
        }
    }
}
