// ------------------------------------------------------------
// Targets
// ------------------------------------------------------------

.targets {
    @extend %pie-clearfix;
    &__item {
        @extend %pie-clearfix;
        margin-bottom: 2em;
        @include respond-to($b-vp) {
            float: left;
            width: 50%;
            padding-right: 10px;
            &:nth-child(2n+1) {
                clear: left;
            }
        }
        @include respond-to($c-vp) {
            width: 33.33%;
            &:nth-child(2n+1) {
                clear: none;
            }
            &:nth-child(3n+1) {
                clear: left;
            }
        }
        @include respond-to($d-vp + 100) {
            width: 20%;
            &:nth-child(2n+1) {
                clear: none;
            }
            &:nth-child(3n+1) {
                clear: none;
            }
            &:nth-child(5n+1) {
                clear: left;
            }
        }
    }
}

.targets__media {
    width: 30%;
    margin-right: 5%;
    float: left;
    img {
        @extend %max-width;
        @include respond-to-max($d-vp) {
            max-width: 80px;
        }
    }
}

.targets__content {
    width: 65%;
    float: left;
}

.targets__heading {
    @extend %font-heading;
    @include font-size($para);
}

.targets__note {
    @include font-size($zeta);
    &--status {
        position: relative;
        padding-left: 30px;
        margin-top: 5px;
        display: block;
        &:before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0;
            width: 25px;
            height: 25px;
            background-color: $gnr-cloud;
            display: block;
            border-radius: 50%;
        }
        &--on {
            &:before {
                background-color: $gnr-aqua;
            }
        }
        &--off {
            &:before {
                background-color: $gnr-sunrise;
            }
        }
        &--progress {
            &:before {
                background-color: $gnr-sky-light;
            }
        }
    }
}

.targets__progress {
    position: relative;
    margin: 5px 0;
    .targets__progress-standard {
        width: 100%;
        display: block;
        height: 10px;
        background-color: lighten($gnr-cloud, 5%);
        border-radius: 10px;
    }
    .targets__progress-active {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 10px;
        background-color: $gnr-aqua;
        border-radius: 10px;
    }
}
