// -----------------------------------------------------------
// pagination
// -----------------------------------------------------------
@use "sass:math";

.pagination {
    display: inline-block;
    margin: 0 auto;
    background: lighten($gnr-cloud, 2%);
}

.pagination__prev, .pagination__current, .pagination__next {
    padding: 1rem 1.5rem;
    height: 50px;
    max-height: 37px;
    float: left;
}

.pagination__prev, .pagination__next {
    position: relative;
    width: 40px;
    background: $gnr-cloud;
    transition: $transition;
    border-left: 1px solid $body-color;
    border-right: 1px solid $body-color;
    .ico {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        -webkit-transform: translateY(-50%) translateX(-50%);
    }
    span {
        visibility: hidden;
    }
}

.pagination__current {
    text-transform: uppercase;
    letter-spacing: 1px;
    @extend .heading;
    @include font-size(math.div($eta, 1.25));
}

.pagination__next, .pagination__prev {
    &:hover {
        background: darken($gnr-cloud, 10%);
    }
}
