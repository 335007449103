// ------------------------------------------------------------
// Rulers aka borders
// See fill-color.scss for colour variants
// ------------------------------------------------------------

.rule 					{ border-color: $default-border-color; border-style: solid; }

// Under and overlines defaults
// ------------------------------------------------------------

.rule--overline 		{ border-top-width: $default-border-width; }
.rule--underline 		{ border-bottom-width: $default-border-width; }
.rule--left 			{ border-left-width: $default-border-width; }
.rule--right 			{ border-right-width: $default-border-width; }
.rule--all 				{ @extend .rule--overline; @extend .rule--underline; @extend .rule--left; @extend .rule--right; }