// ------------------------------------------------------------
// personTeaser
// ------------------------------------------------------------
@use "sass:math";

.personTeaser {
	position: relative;
	display: flex;
	margin-bottom: 3rem;
	&:before {
		z-index: 0;
		background-size: 5%;
	}
	&:after {
		content: '';
		position: absolute;
		z-index: 0;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: $gnr-slate;
		background-image: url(../img/aqua-dots.png);
		background-size: 8%;
		html.svg & {
		    background-image: url(../svg/source/aqua-dots-02.svg);
		}
	}
	&.active {
		.personTeaser__img {
			&:after, &:before {
				width: 100%;
				transition-delay: 0ms;
			}
		}
		.personTeaser__bio {
			opacity: 1;
			max-height: 1000px;
			transition-delay: 200ms;
		}
	}
	&:last-of-type {
			margin-bottom: 0;
	}
	&:nth-last-of-type(2) {
		@include respond-to($c-vp) {
			margin-bottom: 0;
		}
	}
	@media (hover: hover) {
		&:hover {
			.personTeaser__name {
				&:after {
					width: 100%;
					right: auto;
					left: 0;
				}
			}
		}
	}
	&.personTeaser-noBio {
		&.active {
			.personTeaser__img {
				&:after, &:before {
					display: none;
				}
			}
		}
		&:hover {
			.personTeaser__name {
				&:after {
					display: none;
				}
			}
		}
	}
}

.personTeaser__img {
	position: relative;
	z-index: 1;
	float: left;
	width: 40%;
	min-height: 200px;
	background-size: cover;
	background-position: center;
	&:after, &:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 0;
		background: $gnr-slate;
		transition: $transition;
		mix-blend-mode: color;
		transition-delay: 200ms;
	}
	&:before {
		z-index: 1;
		mix-blend-mode: unset;
		opacity: 0.9;
	}
	html.no-js & {
		&:after, &:before {
			width: 100%;
			transition-delay: 0ms;
		}
	}
}

.personTeaser__content {
	position: relative;
	z-index: 2;
	float: left;
	width: 60%;
	min-height: 200px;
	background: $gnr-slate;
	padding: 2rem 2rem 7rem;
	html.no-js & {
		padding-bottom: 2rem;
	}
}

.personTeaser__name {
	display: inline-block;
	position: relative;
	padding-top: 1rem;
	margin: 0 1rem 0 0;
	color: $body-color;
	@include font-size($delta*1.25);
	line-height: 1.2;
	@extend .heading;
	&:after, &:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: $border-width;
		background: #fff;
		transition: $transition;
		transition-delay: 100ms;
	}
	&:after {
		width: 0;
		background: $action;
	}
}

.personTeaser__role {
	display: block;
	margin-bottom: 2rem;
	color: $body-color;
	@include font-size($epsilon);
	line-height: 1.3;
	margin-top: 0.75em;
}

.personTeaser__bio {
	overflow: hidden;
	position: relative;
	z-index: 2;
    opacity: 1;
    max-height: 100%;
	width: 160%;
	html.js & {
		opacity: 0;
		max-height: 0;
		transition: $transition;
		transition-delay: 0ms;
	}
}

.personTeaser__bio__text {
	color: $body-color;
	margin-bottom: 1rem;
	@include font-size(math.div($delta, 1.25));
	&:last-of-type {
		margin-bottom: 2rem;
	}
}
.personTeaser__bio__download__link {
	line-height: 1.5;
	display: none;
	html.no-js &,
	.active & {
		display: inline-block;
	}
}

.personTeaser__year {
	color: $body-color;
	@include font-size(math.div($delta, 1.3));
	margin-bottom: 0.5rem;
}

.personTeaser__button {
	position: absolute;
	left: 2rem;
	bottom: 2rem;
	&:hover {
		background: $action;
		border-color: $action;
	}
	&.button {
		border: 1px solid #fff;
		width: auto;
	}
}

.personTeaser__swap {
	.button + .button {
		margin: 0;
	}
	html.no-js & {
		display: none;
	}
}
