// ------------------------------------------------------------
// Footer
// ------------------------------------------------------------
.footer {
	background: $gnr-cloud;
	position: relative;
	&:after {
		@include respond-to($d-vp) {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background: linear-gradient(to right, lighten($gnr-cloud, 5%) 30%, $gnr-cloud 30%);
		}
	}
}

.footer__content {
	position: relative;
	z-index: 1;
	@include clear;
}

.footer__newsletter {
	padding: 7rem 0 5rem;
	@include respond-to($d-vp) {
		background: lighten($gnr-cloud, 5%);
		width: 45%;
		float: left;
		padding: 7rem 7rem 10rem 0;
	}
}

.footer__links {
	padding: 0 0 8rem;
	@include clear;
	@include respond-to($d-vp) {
		float: right;
		width: 50%;
		padding: 7rem 0 0 3rem;
	}
}

.footer__links__col {
	margin-bottom: 3em;
	@include respond-to($d-vp) {
		width: 50%;
		float: left;
		padding: 0;
		margin-bottom: 0;
	}
}

.footer__social {
	li {
		width: 40px;
		margin-right: 5px;
		display: inline-block;
		a {
			display: block;
		}
	}
}

.footer__newsletter__para {
	margin-bottom: 1rem;
}

.footer__links__item {
	margin-bottom: 1rem;
	@include font-size($zeta);
	padding-right: 20px;
	a {
		color: $gnr-slate;
		&:hover {
			color: $action;
		}
	}
}

.footer__meta {
	@include font-size($eta);
	opacity: 0.75;
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 3rem;
}
