// ------------------------------------------------------------
// blog listing
// ------------------------------------------------------------

// featured item ----------------------------------------------
.featured-item {
	display: block;
	position: relative;
	@include clear;
	&:hover {
		.featured__img {
			&:after {
				left: 0;
				width: 30%;
				width: 30%;
			}
		}
		.meta__date {
			&:after {
				width: 100%;
				right: auto;
				left: 0;
			}
		}
	}
}

.featured__link {
	display: block;
}

.featured__img {
	position: relative;
	height: 200px;
	width: 100%;
	background-size: cover;
	background-position: center;
	@include respond-to($c-vp) {
		height: 350px;
		float: right;
		width: 45%;
	}
	&:after {
		content: '';
		@include respond-to($c-vp) {
			position: absolute;
			top: 0;
			right: 70%;
			width: 0;
			height: 100%;
			background: $action;
			mix-blend-mode: color;
			transition: $transition;
			transition-delay: 150ms;
			@supports not (mix-blend-mode: multiply) {
				opacity: 0.75;
			}
		}
	}
}

.featured__content__wrapper {
	background: $gnr-slate;
	padding: 1.5rem;
	@include respond-to($c-vp) {
		position: absolute;
		left: 0;
		top: 0;
		width: 55%;
		height: calc(100% - 1px);
		background: $gnr-slate;
		padding: 3rem;
	}
}

.featured__content {
	@include respond-to($c-vp) {
		position: absolute;
		left: 3rem;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		width: calc(100% - 6rem);
	}
	.meta__date {
		padding-top: 2rem;
	}
}



.featured__heading {
	display: block;
	color: #fff;
	margin-bottom: 1.5rem;
	transition: $transition;
}

.featured__para {
	color: #fff;
	margin-bottom: 2.5rem;
}



// meta data --------------------------------------------------


// blog listing -----------------------------------------------
.blog__item {
	margin-bottom: 4rem;
	&:nth-last-of-type(1), &:nth-last-of-type(2) {
		@include respond-to($b-vp) {
			margin-bottom: 0;
		}
	}
	&:nth-last-of-type(1), &:nth-last-of-type(2), &:nth-last-of-type(3) {
		@include respond-to($c-vp) {
			margin-bottom: 0;
		}
	}
	.meta__date, .meta__author, .meta__author__job {
		color: $gnr-slate;
	}
	.meta__date {
		&:before {
			background: $gnr-slate;
		}
		&:after {
			background: $action;
		}
	}
	&:hover {
		.blog__img {
			&:after {
				left: 0;
				right: auto;
				width: 30%;
			}
		}
		.meta__date {
			&:after {
				right: auto;
				left: 0;
				width: 100%;
			}
		}
	}
}

.blog__link {
	display: block;
}

.blog__img {
	position: relative;
	height: 200px;
	width: 100%;
	background-size: cover;
	background-position: center;
	margin-bottom: 2rem;
	@include respond-to($c-vp) {
		height: 200px;
	}
	&:after {
		content: '';
		@include respond-to($c-vp) {
			position: absolute;
			top: 0;
			right: 70%;
			width: 0;
			height: 100%;
			background: $action;
			mix-blend-mode: color;
			transition: $transition;
			@supports not (mix-blend-mode: multiply) {
				opacity: 0.75;
			}
		}
	}
	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: $gnr-slate;
		background-image: url(../img/aqua-dots-01.png);
		background-size: 10%;
		html.svg & {
		    background-image: url(../svg/source/aqua-dots-02.svg);
		}
	}
}

// ie 11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.blog__img:after {
		opacity: 0.75;
	}
}

.blog__content {
	position: relative;
}

.blog__heading {
	margin-bottom: 1rem;
	transition: $transition;
}

.blog__para {
	color: $gnr-slate;
	margin-bottom: 1rem;
}
