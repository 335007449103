@use "sass:math";

@import "core/reset";
@import "core/settings";
@import "core/mixins";
@import "core/placeholders";

/* ------------------------------------------------------------ */

html {
	font-size: 10px;
	font-size: 62.5%;
	scroll-behavior: smooth;
	// Mobile (iPhone) can sometimes be a git about font resizing. Adding to a max-media query solves it
	@include respond-to-max($device-safezone-vp) {
		-webkit-text-size-adjust: 100%;
	}
}

body {
	@extend %font-body;
	@include leading($base-font-size);
	background: $body-color;
	color: $base-color;
	counter-increment: page;
	*:focus-visible {
		outline-width: 3px;
		outline-style: solid;
		outline-color: $gnr-sunset;
	}
}

/* :: Granular and Modular styles */
/* ------------------------------------------------------------ */

// Import core modules that pertain to styling
@import "core/base";
@import "core/forms";
@import "core/fonts";
@import "core/typography";

// Import all modules
@import "modules/_alerts.scss";
@import "modules/_aside.scss";
@import "modules/_autocomplete.scss";
@import "modules/_blog-listing.scss";
@import "modules/_branding.scss";
@import "modules/_buttons.scss";
@import "modules/_card.scss";
@import "modules/_chapter-block.scss";
@import "modules/_charts.scss";
@import "modules/_commitment.scss";
@import "modules/_countries-block.scss";
@import "modules/_data.scss";
@import "modules/_drawer.scss";
@import "modules/_eventsListing.scss";
@import "modules/_filter.scss";
@import "modules/_footer.scss";
@import "modules/_forms.scss";
@import "modules/_future-report.scss";
@import "modules/_goal.scss";
@import "modules/_header.scss";
@import "modules/_heroes.scss";
@import "modules/_highlight.scss";
@import "modules/_horizontalListing.scss";
@import "modules/_iconlist.scss";
@import "modules/_icons.scss";
@import "modules/_keyframes.scss";
@import "modules/_layout.scss";
@import "modules/_listing.scss";
@import "modules/_logo.scss";
@import "modules/_media.scss";
@import "modules/_meta.scss";
@import "modules/_modules.scss";
@import "modules/_navigation.scss";
@import "modules/_pagination.scss";
@import "modules/_personTeaser.scss";
@import "modules/_pullOutBlock.scss";
@import "modules/_quantity.scss";
@import "modules/_reportCard.scss";
@import "modules/_responsive-graph.scss";
@import "modules/_rows.scss";
@import "modules/_rulers.scss";
@import "modules/_search.scss";
@import "modules/_sections.scss";
@import "modules/_slider.scss";
@import "modules/_swup.scss";
@import "modules/_tables.scss";
@import "modules/_targets.scss";
@import "modules/_teaser.scss";
@import "modules/_typography-additions.scss";

// Import all items required further up the stack
@import "modules/priority-extends/_backgrounds.scss";
@import "modules/priority-extends/_color.scss";
@import "modules/priority-extends/_spacing.scss";


/* ::Print */
/* ------------------------------------------------------------ */

@media print {

	body {
		font-size: percentage(math.div(10, 16));
		color: #000!important;
		background-color: #fff!important;
	}
	*[role="navigation"],
	*[role="footer"] {
		display: none!important;
	}

	// Targets in profiles
	.targets {
	    overflow: hidden;
	}

	.targets__media {
	    width: 30%;
	    margin-right: 5%;
	    float: left;
	}

	.targets__content {
	    width: 65%;
	    float: left;
	}
}
