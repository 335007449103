// ------------------------------------------------------------
// case study block
// ------------------------------------------------------------
.pullOutBlock {
	position: relative;
	padding: 3rem 0 0 0;
	margin-bottom: 1rem;
	&:after {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		height: calc(100% + 3rem);
		width: 100%;
		opacity: 0.8;
		background-size: 10px;
		background-image: url(../img/aqua-dots.png);
		html.svg & {
			background-size: 4%;
		    background-image: url(../svg/source/aqua-dots-02.svg);
		    @include respond-to($c-vp) {
		    	background-size: 2%;
		    }
		}
	}
	&:before {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		height: calc(100% + 3rem);
		width: 15%;
		width: calc(((100% - 800px) / 2) - 3rem);
		background: #fff;
	}
}

.pullOutBlock__image {
	display: block;
	height: 250px;
	width: 100%;
	background-size: cover;
	background-position: center;
	margin-bottom: 2rem;
}

.pullOutBlock__headingWrapper {
	display: inline-block;
	//border-bottom: $border-width solid $gnr-aqua;
	padding-bottom: 0.5rem;
	margin-bottom: 3rem;
	@include respond-to($d-vp + 180px) {
		position: absolute;
		left: 3rem;
		top: 0;
		max-width: 250px;
	}
	@include respond-to($e-vp) {
		left: 10%;
		max-width: 150px;
	}
}

.pullOutBlock__heading {
	position: relative;
	color: $gnr-aqua;
}

.pullOutBlock__content {
	background: #fff;
	padding: 2rem;
	@include respond-to($c-vp) {
		padding: 3rem;
	}
	&--first {
		padding-bottom: 0;
		h1 {
			margin-bottom: 0;
		}
	}
	&--media {
		padding-top: 0;
		padding-bottom: 0;
		.media-figure {
			margin: 0;
			img {
				margin: 0;
			}
		}
	}
	&.is-typeset .rich-text {
		> h1:first-child,
		> h2:first-child,
		> h3:first-child,
		> h4:first-child,
		> h5:first-child,
		> h6:first-child {
			margin-top: 0;
		}
	}
	&.is-typeset {
		> h1:first-child,
		> h2:first-child,
		> h3:first-child,
		> h4:first-child,
		> h5:first-child,
		> h6:first-child {
			margin-top: 0;
		}
	}
}
