// ------------------------------------------------------------
// Drawer
// ------------------------------------------------------------

.drawer {
    margin-top: $internal-spacing;
    +.drawer {
        margin-top: 20px;
    }
    &:last-of-type {
        margin-bottom: $internal-spacing;
    }
}

.drawer[open] {
    .drawer__title {
        background-color: $gnr-slate;
        color: #fff;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .drawer__title::before {
        transform: rotate(0deg);
        left: 17px;
        top: 19px;
        background-image: url(/assets/svg/source/drop.svg);
    }
}

.drawer__title {
    position: relative;
    @extend .type-m;
    @extend .heading;
    padding: 16px;
    padding-left: 48px;
    background-color: $gnr-cloud;
    transition: all, ease-in-out, 0.1s;
    cursor: pointer;
    border-radius: 5px;
    list-style: none;
    &::-webkit-details-marker {
        display: none;
    }
    &:before {
        content: "";
        background-image: url(/assets/svg/source/drop-blue.svg);
        transform: rotate(-90deg);
        width: 15px;
        height: 15px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: $internal-spacing;
        display: block;
        position: absolute;
        left: 15px;
        top: 21px;
        transition: all, ease-in-out, 0.1s;
    }
}

.drawer__content {
    padding: 24px 48px;
    background-color: $gnr-cloud;
    &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .typebase {
        h1,h2,h3,h4,h5,h6,p,ol,ul,li {
            color: $heading-color;
        }
    }
}

.drawer__content__heading {
    @extend .heading;
    @extend .type-l;
    margin-bottom: $internal-spacing;
}

// Filter drawer

.filter-drawer {
    border-top: 1px solid $gnr-cloud;
}

.filter-drawer:first-of-type {
    margin-top: 20px;
}

.filter-drawer[open] {
    .filter-drawer__title {
        // color: #fff;
    }
    .filter-drawer__title::before {
        transform: rotate(0deg);
        left: 20px;
        top: 10px;
        background-image: url(/assets/svg/source/drop-blue.svg);
    }
}

.filter-drawer__title {
    position: relative;
    @extend .type-xs;
    // @extend .heading;
    font-weight: 700;
    padding: .5em 1em;
    padding-left: 3em;
    background-color: $gnr-air;
    transition: all, ease-in-out, 0.1s;
    cursor: pointer;
    list-style: none;
    &::-webkit-details-marker {
        display: none;
    }
    &:before {
        content: "";
        background-image: url(/assets/svg/source/drop-blue.svg);
        transform: rotate(-90deg);
        width: 15px;
        height: 15px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        left: 15px;
        top: 13px;
        transition: all, ease-in-out, 0.1s;
    }
}

.filter-drawer__content {
    padding: 10px 20px 20px;
    background-color: $gnr-air;
}
