// ------------------------------------------------------------
// Heroes
// ------------------------------------------------------------

.hero {
    @extend .background-cover;
    background-position: center top;
    position: relative;
    padding: 5em 0;
    &:before {
		content: '';
		position: absolute;
		z-index: -1;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: $gnr-slate;
		background-image: url(../img/aqua-dots.png);
		background-size: 2%;
		html.svg & {
		    background-image: url(../svg/source/aqua-dots-02.svg);
		}
	}
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        @include rgba(#000, 0.4);
    }
    @include respond-to($menu-respond) {
        margin-top: -70px;
        padding: 13em 0 11em;
    }
    @include respond-to($menu-respond + 116px) {
        margin-top: -50px;
    }
    &--plain {
        @include respond-to($menu-respond) {
            padding: 11em 0 6em;
        }
        &:before {
            background-image: none;
            html.svg & {
                background-image: none;
            }
        }
        &:after {
            background-color: transparent;
        }
    }
    &--full {
        height: calc(100vh - 100px);
        min-height: 440px;
        padding: 0;
        @include respond-to($menu-respond) {
            height: calc(100vh - 170px);
        }
    }
    &--equal {
        padding: 5em 0 4rem;
        @include respond-to($menu-respond) {
            padding: 15em 0 9rem;
        }
    }
    &--home {
        @include respond-to-max($c-vp) {
            background-image: none !important;
        }
        position: relative;
        overflow: hidden;
        background: $gnr-slate;
        width: 100%;
        height: 100%;
        color: $body-color;
        padding: 12rem 0 3rem;
        //min-height: 700px;
        //display: grid;
        background-size: cover;
        @include respond-to($menu-respond) {
            padding: 12rem 0;
            //height: calc(100vh - 170px);
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            @include rgba(#000, 0.5);
            mix-blend-mode: multiply;
        }
    }
    &--goal {
        background-color: $gnr-coral;
    }
    .button {
        margin-top: 2rem;
    }
}

.hero-full__center {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    margin-bottom: 5rem;
    @include respond-to($c-vp) {
        margin-bottom: 0;
    }
    .hero__heading {
        @include respond-to($c-vp) {
            margin-top: 3rem;
        }
    }
}

.hero__pattern {
    background-size: 25%;
    background-image: url(../img/orange-dots-01.png);
    background-repeat: repeat;
    html.svg & {
        background-image: url(../svg/source/orange-dots-02.svg);
    }
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 14%;
    height: 100%;
    display: block;
    @include respond-to($c-vp) {
        background-size: 14%;
        height: 110%;
    }
    @include respond-to($e-vp) {
        width: 15%;
    }
    .hero--home & {
        width: 100%;
        height: 100px;
        display: block;
        opacity: 0.5;
        background-size: 5%;
        @include respond-to($c-vp) {
            height: 35%;
            background-size: 2%;
        }
        @include respond-to($e-vp) {
            width: 100%;
        }
    }
}

// target ie11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html.svg .hero__pattern {
        background-image: url(../img/orange-dots-01.png);
    }
}

.hero__pattern-full {
    height: 100%;
}

.hero__caption {
    position: relative;
    z-index: 2;
    &--center {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        @include respond-to($d-vp) {
            max-width: 800px;
        }
    }
    &--float {
        @include respond-to($d-vp) {
            width: 70%;
            float: left;
            padding-left: 15%;
        }
    }
}

.hero__aside {
    position: relative;
    z-index: 2;
    margin-top: 2em;
    @include respond-to($sidebar-respond) {
        float: right;
        display: inline-block;
        width: 20%;
        margin-top: 0;
    }
}

.hero__chapter {
    @extend %font-heading;
    color: $gnr-sand;
    border-bottom: $border-width solid $gnr-sand;
    margin-bottom: 1em;
    line-height: 1.1;
    display: inline-block;
    @include font-size($beta);
    @include respond-to($menu-respond) {
        @include font-size($alpha);
    }
}

.hero__heading {
    @extend %font-heading;
    @include font-size($alpha);
    color: #fff;
    line-height: 1.2;
    @include respond-to($menu-respond) {
        @include font-size($alpha-papa);
        max-width: 650px;
    }
    &--longer {
        @include respond-to($menu-respond) {
            max-width: 750px;
        }
    }
    &--full {
        @include respond-to($menu-respond) {
            max-width: 100%;
        }
    }
    // &--small {
    //     @include font-size($alpha);
    // }
}

.heading-center {
    text-align: center;
    max-width: 100%;
}

.hero__heading-sml {
    @extend %font-heading;
    @include font-size($gamma);
    color: #fff;
    line-height: 1.3;
    margin-bottom: 1.5rem;
    @include respond-to($c-vp) {
        @include font-size($beta);
    }
    @include respond-to($menu-respond) {
        //@include font-size($alpha / 1.25);
        margin-bottom: 2rem;
    }
}

.hero__subheading {
    @extend %font-heading;
    @include font-size($beta);
    color: #fff;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    @include respond-to($menu-respond) {
        margin-bottom: 4rem;
    }
    &--small {
        @include font-size($gamma);
    }
    a {
        color: #fff;
        border-bottom: 1px solid #fff;
        @include link-active-styles {
            border-bottom: 0;
        }
    }
}

.hero__heading-md {
    @extend %font-heading;
    @include font-size($alpha-md);
    color: #fff;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    @include respond-to($menu-respond) {
        //@include font-size($beta / 1.25);
        margin-bottom: 2rem;
    }
}

.hero__para {
    color: #fff;
    margin-top: 2rem;
    font-size: 1.45rem;
    text-shadow: 0px 0px 5px rgba(0,0,0,0.75);
    @include respond-to($menu-respond) {
        @include font-size($para * 1.15);
        max-width: 550px;
    }
}

.hero__text {
    color: #fff;
    margin-top: 2rem;
    // @include font-size($para);
    @include font-size(13);
    max-width: 100%;
    margin-bottom: 4rem;
    a {
        color: #fff;
        border-bottom: 1px solid #fff;
        @include link-active-styles {
            border-bottom-width: 0;
        }
    }
}

.hero__flag {
    @include font-size($eta);
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0.25em 0.5em;
    margin-bottom: 1em;
    display: inline-block;
    background-color: #fff;
}

.hero__cut {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    background: #fff;
    display: none;
    @include respond-to($menu-respond) {
        width: 241px;
        height: 54px;
    }
    @include respond-to($c-vp) {
        width: 227px;
    }
    .no-js & {
        display: none;
    }
}

// hero colour
.hero-colour {
    padding: 5em 0;
    @include respond-to($menu-respond) {
        margin-top: -36px;
        padding: 12em 0;
    }
    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        background: lighten($gnr-cloud, 5%);
        height: 60%;
        width: 100%;
    }
    .hero__pattern {
        height: 100%;
    }
}

// contents ---------------------------------------------------
.hero__contents {
  position: relative;
  z-index: 1;
  @include respond-to($c-vp) {
    text-align: right;
    width: 60%;
    max-width: 350px;
    float: right;
  }
}

.hero__contents__item {
  display: block;
}

.hero__contents__img {
  width: 100%;
  height: 175px;
  background-size: cover;
  background-position: center;
  @include respond-to($c-vp) {
    display: none;
  }
}

.hero__contents-wrapper {
  padding: 1.5rem 1rem;
  background: $gnr-cloud;
  @include respond-to($c-vp) {
    background: transparent;
    padding: 0;
  }
}

.hero__contents__para {
  @include font-size($para);
  color: $gnr-slate;
  @include respond-to($c-vp) {
    color: $body-color;
    display: none;
  }
}

.hero__contents__link {
  position: relative;
  display: block;
  padding: 1.5rem 0;
  @include respond-to($c-vp) {
    padding-left: 2rem;
  }
  transition: $transition-slow;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: auto;
    bottom: 18px;
    height: 0;
    width: $border-width;
    background: $body-color;
    transition: $transition-slow;
  }
  &:hover, &.active {
    @include respond-to($c-vp) {
      padding-left: 0;
      padding-right: 2rem;
    }
    &:after {
      @include respond-to($c-vp) {
        bottom: auto;
        top: 18px;
        height: calc(100% - 40px);
      }
    }
  }
}

.hero__contents__chapter-number {
  position: relative;
  display: inline-block;
  @include small-caps;
  @extend %font-heading;
  color: $gnr-slate;
  padding-top: 1.5rem;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: $border-width;
    background: $gnr-slate;
  }
  @include respond-to($c-vp) {
    padding-top: 0;
    display: block;
    color: $body-color;
    &:after {
      display: none;
    }
  }
}

.hero__contents__chapter-name {
  display: block;
  color: $body-color;
  @include font-size($delta*1.1);
  @extend %font-heading;
  color: $gnr-slate;
  @include respond-to($c-vp) {
    color: $body-color;
  }
}

.hero__credit {
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 0;
    font-size: 1.3rem;
    color: #fff;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 0.25em 0.5em;
    display: block;
    @include respond-to($menu-respond) {
        .hero--full & {
            right: 236px;
        }
    }
    a {
        color: #fff;
        text-decoration: underline;
    }
}
