// ------------------------------------------------------------
// Swup
// ------------------------------------------------------------

.a-fade {
    position: relative;
    transition: all, ease-in-out, 1s;
    &:after {
        content: "";
        position: absolute;
        z-index: 5;
        top: -25px;
        right: 0;
        width: 0;
        height: 100%;
        display: block;
        background-color: $gnr-sunset;
        transition: all, ease-in-out, 0.75s;
        transition-delay: 0.25s;
    }
    &.top {
        &:after {
            z-index: 30;
        }
    }
    .navigation-chapter {
        transform: translateY(0);
        opacity: 1;
        transition: all, ease-in-out, 0.5s;
    }
    .hero__caption {
        transform: translateX(0);
        opacity: 1;
        transition: all, ease-in-out, 0.5s;
        transition-delay: 0.5s;
    }
}

html.is-animating .a-fade{
    &:after {
        width: 100%;
        left: 0;
    }
    .navigation-chapter {
        transform: translateX(-100%);
        opacity: 0;
        transition: all, ease-in-out, 0.5s;
    }
    .hero__caption {
        transform: translateY(-100%);
        opacity: 0;
        transition: all, ease-in-out, 0.5s;
    }
}

.swup-loading {
    position: absolute;
    z-index: 200;
    top: 25em;
    width: 300px;
    margin: 0 auto;
    left: 50%;
    margin-left: -150px;
    opacity: 0;
    span {
        border: 3px solid #fff;
        padding: 1em;
        text-transform: uppercase;
        letter-spacing: 1px;
        @include font-size($delta);
        color: #fff;
    }
}

html.is-changing .swup-loading{
    animation: loading 1.2s;
    animation-delay: 0.5s;
    // transition: all ease-in-out, 0.3s;
    // transition-delay: 0.4s;
}
