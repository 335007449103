// ------------------------------------------------------------
// Modules
// prefix with m-
// ------------------------------------------------------------

@use "sass:math";

.m-reveal {
    position: relative;
    z-index: 10;
    .button {
        margin-bottom: 3em;
        &:after {
            content: "more";
        }
    }
    &--open {
        + .meta {
            @include respond-to($menu-respond) {
                margin-top: -100px;
            }
        }
        .button {
            &:after {
                content: "less";
            }
        }
    }
}

.m-reveal__body {
    overflow: hidden;
    display: none;
}

.m-profile-filter {
    .hero & {
        position: relative;
        z-index: 2;
        margin-top: 5em;
    }
    &__heading {
        @include font-size($gamma);
        @extend %font-heading;
        margin-bottom: 0.5em;
        .hero & {
            color: #fff;
        }
    }
    input {
        @include respond-to($c-vp) {
            height: 60px;
        }
    }
    button[type="submit"] {
        height: 44px;
        position: absolute;
        top: 0;
        right: 0;
        @include respond-to($c-vp) {
            height: 60px;
        }
    }
    .countries-menu-list {
        margin-bottom: 0;
        .countries-menu-list__item span {
            color: $gnr-sunrise;
            // margin-left: 20px;
        }
    }
}

.m-profile-filter__results {
    margin-top: 1em;
    @include font-size($para);
    &-item {
        margin-top: 0.5em;
        li {
            display: inline-block;
            position: relative;
            margin-right: 10px;
            &:after {
                content: ">";
                margin-left: 10px;
                color: $heading-color;
            }
            &:last-child {
                &:after {
                    content: none;
                }
            }
        }
    }
}

.m-search-highlight {
    background-color: $gnr-sand;
    color: $heading-color;
}

.m-intro {
    @extend %pie-clearfix;
    scroll-margin-top: 50px;
    &__main {
        margin-bottom: 1em;
        @include respond-to($c-vp) {
            float: left;
            width: 60%;
        }
    }
    &__aside {
        margin-top: 6em;
        @include respond-to($c-vp) {
            margin-top: 0;
            float: right;
            width: 35%;
        }
    }
    &--list {
        margin-bottom: 3em;
        padding-bottom: 2em;
        border-bottom: 2px solid lighten($gnr-cloud, 5%);
    }
}

.m-intro__heading {
    @extend %font-heading;
    @include font-size($beta);
    @include leading(math.div($alpha, 1.75));
    margin-bottom: 0.5em;
    display: inline-block;
    margin-right: 10px;
    scroll-margin-top: 50px;
    a {
        color: $heading-color;
    }
}

.m-intro__subheading {
    @include font-size($gamma);
    color: $gnr-sunset;
    margin-bottom: 1em;
    line-height: 1.3;
    .l-3up__col &, .l-4up__col & {
        min-height: 100px;
    }
}

.m-intro__media {
    margin-bottom: 1em;
    display: block;
    img,
    &__img {
        @extend %max-width;
    }
    &--3up {
        @extend %pie-clearfix;
        img {
            float: left;
            width: 33.33%;
        }
    }
    &--2up {
        @extend %pie-clearfix;
        img,
        &__img {
            float: left;
            width: 50%;
        }
    }
}

.m-intro__type {
    margin-bottom: 1em;
    &__heading {
        @include font-size($para);
        text-transform: uppercase;
        letter-spacing: 1px;
        color: darken($gnr-cloud, 25%);
        margin-bottom: 0.25em;
        display: block;
    }
}

.m-notes {
    @include font-size($para);
    color: darken($gnr-cloud, 25%);
    max-width: 800px;
    margin-bottom: 2em;
    p {
        margin-bottom: 0.5em;
    }
    a {
        text-decoration: underline;
        &:hover {
            color: $gnr-coral;
        }
    }
}

.m-block {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $gnr-cloud;
    margin-bottom: 1em;
    &__item {
        display: flex;
        flex-direction: column;
        position: relative;
        //padding: 1em;
        width: 50%;
        border-right: 1px solid $gnr-cloud;
        text-align: center;
        border-bottom: 1px solid $gnr-cloud;
        background-color: $gnr-cloud;
        &:nth-child(2n) {
            border-right: none;
        }
        @include respond-to($c-vp) {
            width: 20%;
            &:nth-child(2n) {
                border-right: 1px solid $gnr-cloud;
            }
            &:nth-child(5n) {
                border-right: none;
            }
        }
        // @include respond-to($e-vp) {
        //     width: 11.11%;
        //     &:nth-child(5n) {
        //         border-right: 1px solid $gnr-cloud;
        //     }
        //     &:nth-child(9n) {
        //         border-right: none;
        //     }
        // }
        &--on-course {
            background-color: $gnr-sky-light;
        }
        &--reached {
            background-color: $gnr-aqua;
        }
        &--off-course {
            background-color: $gnr-sunrise;
        }
    }
}

.m-block__copy {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @include font-size($para);
    @extend %font-heading;
    line-height: 1.3;
    padding: 1em;
    background-color: $gnr-concrete;
    color: #fff;
    min-height: 64px;
}

.m-block__status {
    padding: 1em;
    display: block;
    width: 100%;
    color: $gnr-slate;
    @include font-size($para);
    .m-block__item--off-course & {
        color: #fff;
    }
    .m-block__item--reached & {
        color: darken($gnr-slate, 5%);
    }
    .ico {
        background-size: contain;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        margin-right: 5px;
        .m-block__item--on-course & {
            background-image: url(../svg/source/icon-tick-slate.svg);
        }
        .m-block__item--off-course & {
            color: #fff;
            background-image: url(../svg/source/icon-off-course.svg);
        }
        .m-block__item--reached & {
            color: darken($gnr-slate, 5%);
            background-image: url(../svg/source/icon-tick-slate.svg);
        }
        .m-block__item--undetermined & {
            background-image: url(../svg/source/icon-nodata.svg);
        }
        .m-block__item--no-data & {
            background-image: url(../svg/source/icon-cross-slate.svg);
        }
    }
}

.m-downloads {
    position: relative;
    background-color: #fff;
    padding: 2em 0 2rem 2rem;
    margin: 3em;
    @include respond-to($c-vp) {
        padding: 2em;
    }
    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -3em;
        left: -3em;
        width: 50%;
        height: 110%;
        display: block;
        background-size: 40%;
        background-image: url(../img/coral-dots.png);
        html.svg & {
            background-size: 5%;
            background-image: url(../svg/source/coral-dots-02.svg);
        }
    }
}

.m-downloads__heading {
    @extend %font-heading;
    @include font-size($beta);
    margin-bottom: 0.75em;
}

.m-downloads__list {
    @include font-size($para);
    li {
        margin-bottom: 0.5em;
        a {
            text-decoration: underline;
            @extend .heading;
            &:hover {
                .downloadSize {
                    &:after {
                        background: $action-respond;
                    }
                }
            }
        }
    }
    @include respond-to($c-vp) {
        columns: 2;
        .m-intro__aside & {
            columns: 1;
        }
    }
    .downloadSize {
        &:after {
            background: $action;
        }
    }
}

.m-vertical-align {
    @include respond-to($e-vp) {
        top: -50%;
        transform: translateY(50%);
    }
}

// Search -------------------------------------------------

.m-search {
    margin-bottom: 3em;
    &__heading {
        @extend .aside__heading;
    }
    .button {
        background-color: $action;
        color: #fff;
        height: $form-item-height;
        width: 100%;
        margin-top: 1em;
        @include respond-to($menu-respond) {
            transform: translateY(-3px);
            width: auto;
            margin-top: 0;
        }
    }
    &--progress {
        .no-js & {
            display: none;
        }
    }
}



#swup {
    @include clear;
}
// animations -------------------------------------------------
@keyframes slidein {
  0% {
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
  }
}

// page action ------------------------------------------------
// action
.page__action {
    position: fixed;
    z-index: 30;
    right: 0;
    bottom: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    transition: $transition;
    max-height: 43px;
    @include respond-to($b-vp) {
        max-height: 58px;
    }
    @extend %font-heading;
    &.fix {
        position: fixed;
    }
    &.stop {
        bottom: 0px;
        right: 0;
        position: absolute;
        &.bottom {
            // height of footer
            transform: translateY(586px);
            -webkit-transform: translateY(586px);
            @include respond-to($b-vp) {
                transform: translateY(516px);
                -webkit-transform: translateY(516px);
            }
            @include respond-to($d-vp) {
                transform: translateY(333px);
                -webkit-transform: translateY(333px);
            }
        }
    }
    html.no-js & {
        display: none;
    }
    // reveal in
    html.js & {
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
        animation-duration: 350ms;
        animation-delay: 600ms;
        animation-name: slidein;
        animation-fill-mode: forwards;
    }
    @include respond-to($f-vp) {
        display: none;
    }
}

.page__action__previous, .page__action__next, .page-action__section, .page__action_text, .page__action__burgerWrapper {
    float: left;
    background: $action;
    padding: 15px 10px;
    @include respond-to($c-vp) {
        padding: 20px 20px;
    }
}

.page__action__menu {
    float: left;
    &:hover {
        cursor: pointer;
        .page__action__burgerWrapper, .page__action_text {
            background: $action-respond;
        }
    }
}

.page__action__burgerWrapper {
    transition: $transition;
    padding: 11px 10px 10px 0;
    @include respond-to($c-vp) {
        padding: 17px 20px 17px 0;
    }
    .burger {
        margin: 0;
    }
}

.page__action__previous, .page__action__next {
    transition: $transition;
    padding: 14px 10px;
    @include respond-to($c-vp) {
        padding: 20px 20px;
    }
    &:hover {
        background: $action-respond;
    }
}

.page-action__section {
    @extend .heading;
}

.page__action__pagination {
    float: left;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    @include font-size(math.div($eta, 1.25));
    @include respond-to($c-vp) {
        @include font-size($eta);
    }
}

.page__action__previous {
    border-right: 1px solid $body-color;
    background: $action;
    transition: $transition;
    .ico {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.page__action__next {
    border-right: 1px solid $body-color;
    border-left: 1px solid $body-color;
    background: $action;
    transition: $transition;
    .ico {
        transform: translateX(0);
        -webkit-transform: translateX(0);
    }
}

.page__action__index {
    padding: 2rem;
    @include respond-to($c-vp) {
        padding: 2rem 3rem;
    }
    html.no-js & {
        display: none;
    }
}

.page__action_text {
    float: left;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    transition: $transition;
    @include font-size(math.div($eta, 1.25));
    @include respond-to($c-vp) {
        @include font-size($eta);
    }
}

.page-icon-adjust {
    position: relative;
    top: 4px;
}


.page__action__no-js-link {
    color: $body-color;
    display: none;
    html.no-js & {
        display: inline-block;
    }
}

.page__action__js-text {
    display: none;
    html.js & {
        display: inline-block;
    }
}


// downloadChapter --------------------------------------------
.mainAction {
    @include clear;
}

.download {
    margin-bottom: 3rem;
    @include respond-to($d-vp) {
        margin-bottom: 0;
        width: 40%;
        width: calc(40% - 40px);
        float: left;
    }
    &-full {
        @include respond-to($d-vp) {
            width: 100%;
        }
        .download__item {
            &:last-of-type {
                border-bottom: 1px solid rgba($gnr-slate, 0.25);
            }
        }
    }
}

.download__item {
    position: relative;
    padding: 1rem 0;
    border-top: 1px solid rgba($gnr-slate, 0.25);
    &:first-of-type {
        border-top: 0;
    }
}

.download__img {
    position: relative;
    display: inline-block;
    background-size: cover;
    background-position: center;
    height: 50px;
    width: 50px;
}

.download__label {
    text-transform: uppercase;
    letter-spacing: 1px;
    @include font-size(math.div($eta, 1.25));
    @include respond-to($d-vp) {
        @include font-size($eta);
    }
}

.download__link {
    @include font-size($para);
    display: block;
    margin-bottom: 0.5rem;
    text-decoration: underline;
    @extend .heading;
    .downloadSize {
        &:after {
            background: $action;
        }
    }
    &:hover {
        .downloadSize {
            &:after {
                background: $action-respond;
            }
        }
    }
}

// orderReport ------------------------------------------------
.orderReport {
    position: relative;
    @include respond-to($d-vp) {
        width: 60%;
        float: right;
    }
}

.orderReport__img {
    display: block;
    max-width: 250px;
    width: 100%;
    height: 350px;
    margin-bottom: 2rem;
    background-repeat: no-repeat;
    @include respond-to($d-vp) {
        margin-bottom: 0;
        background-size: cover;
        background-position: center;

    }
    &-standardAction {
        width: 100%;
        height: 200px;
        max-width: 100%;
        @include respond-to($d-vp) {
            max-width: 250px;
            width: 100%;
            height: 350px;
        }
    }
    &--fallback {
        @include respond-to-max($d-vp) {
            display: none;
        }
    }
}

.orderReport__content {
    background: #fff;
    @include respond-to($d-vp) {
        padding: 3rem 0 3rem 5rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        left: 30%;
        width: 70%;
    }
}

// shareBlock -------------------------------------------------
.shareBlock {
    position: relative;
    padding: 3rem;
    overflow: hidden;
    text-align: center;
    @include respond-to($c-vp) {
        text-align: left;
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../img/aqua-dots.png);
        html.svg & {
            background-size: 2%;
            background-image: url(../svg/source/aqua-dots-02.svg);
        }
    }
}

.shareBlock__wrapper {
    @include clear;
    &[data-js="share-section"] {
        @include leader($para);
        @include trailer($beta);
        .footnotes &,
        .defintionList__item & {
            @include trailer($para);
        }
        .js & {
            display: none;
        }
    }
    .section--header & {
        margin-top: 1em;
        @include respond-to($menu-respond) {
            margin-top: 0;
            width: 50%;
            float: right;
            transform: translateY(5px);
        }
    }
}

.shareBlock__media {
    @include respond-to($c-vp) {
        float: left;
    }
}

.shareBlock__item {
    width: 40px;
    display: inline-block;
}


.shareBlock__content {
    background: $body-color;
    padding: 3rem;
}

.shareBlock__copy {
    max-width: 100%;
    width: 100%;
    @include respond-to($c-vp) {
        width: calc(100% - 140px);
        float: left;
        margin-left: 5px;
    }
    input {
        display: inline-block;
        width: calc(100% - 72px);
        padding: 9px 1.5rem;
        border: 1px solid $gnr-slate;
        @include font-size($delta);
        opacity: 0.5;
    }
    .button {
        display: inline-block;
        position: relative;
        top: -4px;
        width: auto;
        border: 1px solid $action;
        margin-left: 3px;
        padding: 1.35rem 1.75rem;
        .no-js & {
            display: none;
        }
    }
    &--no-social {
        @include respond-to($c-vp) {
            width: 100%;
            margin-left: 0;
        }
    }
}

// filters ----------------------------------------------------
.filterBlock {
    position: relative;
    transform: translateY(-30px);
    z-index: 1;
    width: 100%;
    text-align: center;
    background: $gnr-cloud;
    li {
        list-style: none;
    }
}

.filters__wrap {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: $transition;
    @include respond-to($c-vp) {
        height: auto;
        display: flex;
        align-items: left;
    }
    &.active {
        height: auto;
    }
    html.no-js & {
        height: auto;
    }
}

.filterBlock__title, .filterBlock__item {
    padding: 1.5rem 2rem;
}

.filterBlock__title {
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    @include font-size(math.div($eta, 1.25));
    flex-basis: 5%;
    padding-right: 0;
    width: 100%;
    text-align: center;
    @include respond-to($c-vp) {
        display: none;
    }
    html.no-js & {
        display: none;
    }
}

.filterBlock__item {
    @include font-size($eta);
    background: transparent;
    transition: $transition;
    color: $gnr-slate-a11y;
    padding: 1rem 2rem;
    a {
        color: $gnr-slate-a11y;
    }
    @include respond-to($c-vp) {
        padding: 1.5rem 2rem;
        @include font-size($eta*1.25);
    }
    &:hover {
        cursor: pointer;
        background: lighten($gnr-cloud, 5%);
    }
}

.filterBlock__item--active {
    background: $action;
    color: $body-color;
    a {
        color: #fff;
    }
    &:hover {
        background: $action;
        color: $body-color;
    }
}

.filterBlock__number {
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    @include font-size(math.div($eta, 1.5));
    @extend %font-sans;
}


// event-actions ----------------------------------------------
.event-actions {
    .button {
        clear: both;
        margin-left: 0;
        // clear inline-block
        display: table;
        text-decoration: none;
        &:last-of-type {
            margin-top: 1rem;
        }
    }
}

// orgainiser -------------------------------------------------
.orgainsier-wrapper {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -2.5em;
        left: -3em;
        width: 100%;
        height: 90%;
        display: block;
        background-size: 40%;
        background-image: url(../img/coral-dots.png);
        @include respond-to($c-vp) {
            top: -3.5em;
            width: 50%;
            height: 113%;
        }
        html.svg & {
            background-size: 7%;
            background-image: url(../svg/source/coral-dots-02.svg);
        }
    }
}

.orgainsier-content {
    background: $body-color;
    padding: 3rem;
    a {
        @extend .heading;
        text-decoration: underline;
    }
}

.orgainsier {
    margin-bottom: 2rem;
    @include respond-to($c-vp) {
        margin-bottom: 0;
    }
    a {
        @extend .heading;
    }
}

.orgainsier__name {
    @extend .heading;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 1rem;
}

.organiser__mail {
    span {
        @extend .heading;
    }
}


.organiser__tel {
    span {
        @extend .heading;
    }
}

// cta_block

.cta-block{
    position: relative;
    padding: 3rem;
    margin-bottom: 3rem;
    @include respond-to($d-vp) {
        padding: 3rem 0 0 0;
        margin-bottom: 6rem;
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.8;
        @include respond-to($d-vp) {
            height: calc(100% + 3em);
            left: -50%;
            width: 160%;
        }
        background-size: 30px;
        background-image: url(../img/aqua-dots.png);
        html.svg & {
            background-size: 3%;
            background-image: url(../svg/source/aqua-dots-02.svg);
            @include respond-to($c-vp) {
                background-size: 3%;
            }
        }
    }
    &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: calc(100% + 3rem);
        width: 15%;
        width: calc(((100% - 800px) / 2) - 3rem);
        background: #fff;
    }
    .cta-block__content{
        background: $body-color;
        padding: 3rem;
        a:not(.button) {
            @extend .heading;
            text-decoration: underline;
        }
    }
    .cta-block__title{
        @extend .type-xl;
        // @include leader($alpha);
        @include trailer(math.div($alpha, 3));
        @extend .heading;
        line-height: 1.3;
    }
}

// section highlight
.sectionHighlight {
    position: relative;
    background: $body-color;
    &:before {
        content: '';
        position: absolute;
        z-index: -2;
        top: -1em;
        left: -1rem;
        width: 70%;
        height: 50%;
        background-size: 40%;
        background-image: url(../img/orange-dots.png);
        @include respond-to($c-vp) {
            top: -5em;
            left: -5rem;
            width: 70%;
        }
        html.svg & {
            background-size: 3%;
            background-image: url(../svg/source/orange-dots-02.svg);
        }
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        left: -3rem;
        top: -3rem;
        background: $body-color;
        width: 100%;
        height: 100%;
    }
    &-reverse {
        &:before {
            left: auto;
            right: 1rem;
            @include respond-to($c-vp) {
                right: -5rem;
            }
        }
        &:after {
            left: auto;
            right: -3rem;
        }
    }
}

// map --------------------------------------------------------
#map {
    width: 100%;
    height: 250px;
}

.map__img {
    display: block;
    width: 100%;
}

// defintionList ----------------------------------------------
.defintionList {

}

.defintionList__item {
    position: relative;
    padding: 1.5rem 0;
    margin-bottom: 2rem;
    &:last-of-type {
        margin-bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: $border-width;
        background: $gnr-slate;
    }
}

.definitionList__term__wrapper {

}

.definitionList__term {
    margin-bottom: 0.75rem;
}

.definitionList__link {
    @include font-size($eta*1.25);
    @extend .heading;
    text-decoration: underline;
}

.definitionList__definition {
    @include font-size(math.div($delta, 1.15));
    margin-bottom: 0.5rem;
}

// footnotes --------------------------------------------------
.footnotes {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    .button {
        margin-top: 1em;
        transform: translateX(-50%);
        left: 50%;
        display: none;
        html.js & {
            display: inline-block;
        }
    }
}

.footnotes__list {
    list-style: decimal;
    margin-bottom: 1rem;
    padding-left: 1rem;
    @include respond-to($c-vp) {
        padding-left: 2rem;
    }
}

.footnotes__sub {
    position: relative;
    display: inline-block;
    padding-top: 1.5rem;
    margin-bottom: 2rem;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: $border-width;
        background: $gnr-slate;
    }
}

.footnotes__item {
    margin-bottom: 2rem;
    &[data-js='toggleable-footnotes--item'] {
        .js & {
            display: none;
        }
    }
}

.footnotes__text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    @include respond-to($c-vp) {
        @include font-size($eta*1.25);
    }
}

.footnotes__link {
    word-break: break-all;
    text-decoration: underline;
    @extend .heading;
}

.footnotes__return {
    @extend %font-heading;
    @include small-caps;
    color: $action;

}

.footnotes__item:target {
  animation: footnotes__highlight 1.5s ease-out;
}

@keyframes footnotes__highlight {
  0% { background-color: lighten($gnr-sunset-a11y, 30%); }
}

// helpers ----------------------------------------------------
.border-top {
    border-top: 1px solid rgba($gnr-slate, 0.25);
}

.border-top-thick {
    border-top: $border-width solid $gnr-slate;
    display: inline-block;
}

.no-mb {
    margin-bottom: 0;
}

.no-mt {
    margin-top: 0;
}

.mt {
    margin-top: 2rem;
}

.no-pb {
    padding-bottom: 0;
}

.no-pt {
    padding-top: 0 !important;
}

.margin-bottom-mobile {
    margin-bottom: 1rem;
    @include respond-to($b-vp) {
        margin-bottom: 0;
    }
}

// backgrounds ================================================
.cloud-bg {
    background: lighten($gnr-cloud, 10%);
}


// simple list with columns =================================================

.simple-list {
    position: relative;
    background-color: #fff;
    padding: 2em 0 2rem 2rem;
    @include respond-to($c-vp) {
        padding: 2em;
    }
    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -3em;
        left: -3em;
        width: 50%;
        height: 110%;
        display: block;
        background-size: 40%;
        background-image: url(../img/coral-dots.png);
        html.svg & {
            background-size: 5%;
            background-image: url(../svg/source/coral-dots-02.svg);
        }
    }
}

.simple-list__heading {
    @extend %font-heading;
    @include font-size($beta);
    margin-bottom: 0.75em;
}

.simple-list__sub-heading{
    @extend %font-heading;
    @include font-size($gamma);
    margin-bottom: 0.75em;
}

.simple-list__list {
    @include font-size($para);
    li {
        margin-bottom: 0.5em;
        a {
            text-decoration: underline;
            @extend .heading;
            &:hover {
                .downloadSize {
                    &:after {
                        background: $action-respond;
                    }
                }
            }
        }
    }
    @include respond-to($c-vp) {
        columns: 2;
        .m-intro__aside & {
            columns: 1;
        }
    }
    .downloadSize {
        &:after {
            background: $action;
        }
    }
}
