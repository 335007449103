// ------------------------------------------------------------
// Keyframes
// ------------------------------------------------------------

@keyframes loading {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
