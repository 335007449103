// ------------------------------------------------------------
// Alerts
// ------------------------------------------------------------

.alert {
	padding: 15px;
	padding-left: 45px;
	overflow: hidden;
	display: block;
	position: relative;
	@include font-size($para);
	line-height: 1.3;
	.form & {
		margin-bottom: 2em;
	}
	.ico {
		width: 18px;
		height: 18px;
		background-size: 18px 18px;
		display: inline-block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
    	-webkit-transform: translateY(-50%);
		left: 15px;
		margin-top: -1px;
	}
}

.alert--notice {
	color: $warning;
	background-color: lighten($warning, 55%);
}

.alert--error {
	@extend .alert--notice;
	background-color: lighten($error, 40%);
	color: $error;
}

.alert--success {
	@extend .alert--notice;
	background-color: #E4F5F0;
	color: $success;
}
