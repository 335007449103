// ------------------------------------------------------------
// futureReport
// ------------------------------------------------------------

.future-report {
  position: relative;
  background-color: darken($gnr-slate, 5%);
  background-size: cover;
  background-position: center;
  @include respond-to($menu-respond) {
    margin-top: -64px;
  }
  @include respond-to($menu-respond + 156px) {
      margin-top: -45px;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    @include rgba(#000, 0.4);
    mix-blend-mode: multiply;
  }
}

.future-report__content {
  position: relative;
  z-index: 1;
  padding: 7rem 0 5rem;
  .button {
      margin-bottom: 1em;
  }
  @include respond-to($menu-respond) {
    padding: 11rem 0 9rem;
    display: flex;
    align-items: center;
  }
}

.future-report__content__copy {
  max-width: 700px;
  padding-right: 4rem;
  margin-bottom: 2rem;
  @include respond-to($menu-respond) {
    margin-bottom: 3rem;
  }
}

.future-report__content__img {
  width: 100%;
  @include respond-to($menu-respond) {
    width: 50%;
  }
  img {
    display: block;
    width: 100%;
  }
}

.future-report__published {
  position: relative;
  display: inline-block;
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  @include small-caps;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $border-width;
    background: $body-color;
  }
}

.future-report__heading {
  color: $body-color;
  @include font-size($beta*1.25);
  @extend .heading;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  @include respond-to($c-vp) {
    @include font-size($beta*1.5);
  }
}

.future-report__intro {
  @include font-size($delta);
  color: $body-color;
  margin-bottom: 2rem;
}

// anchor button
.future-report-nextReport {
  display: none;
  @include respond-to($menu-respond) {
    display: block;
    position: absolute;
    z-index: 5;
    left: 0;
    bottom: 25px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid $body-color;
    background: transparent;
    transition: $transition;
  }
  .ico {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
  }
  &:hover {
    background: $action;
  }
}
