// ------------------------------------------------------------
// Layout
// Defing layouts rather than using a set grid system
// Only define the scaffolding, not modules
// Prefix with 'l'
// ------------------------------------------------------------

%l {
	position: relative;
	display: block;
	@extend %pie-clearfix;
}

// For flexbox layouts
%l-flexbox {
	display: flex;
	flex-wrap: wrap;
}

// ------------------------------------------------------------
// Standard
// ------------------------------------------------------------

.l-standard {
	@extend %l;
}

.l-error {
	@extend %l;
	padding-top: 2em;
}

// ------------------------------------------------------------
// Sidebar
// ------------------------------------------------------------

.l-sidebar {
	position: relative;
	@extend %l;
	&__aside {
		position: relative;
		@include respond-to($sidebar-respond) {
			float: right;
			display: inline-block;
			width: 20%;
			background: $body-color;
		}
	}
	&__aside--minor {
		margin-top: 0 !important;
		.button {
			margin-top: 1em;
		}
	}
	&__body {
		margin-bottom: 2em;
		@include respond-to($sidebar-respond) {
			width: 80%;
			//padding-right: 5%;
			float: left;
			display: inline-block;
			margin-bottom: 0;
			&--padd {
				padding-left: 15%;
				width: 70%;
			}
		}
	}
	&--reverse {
		.l-sidebar__aside {
			margin-bottom: 2em;
			@include respond-to($sidebar-respond) {
				margin-bottom: 0;
				float: left;
				width: 30%;
				margin-top: 0;
				&:before {
					content: none;
				}
			}
		}
		.l-sidebar__body {
			margin-bottom: 0;
			@include respond-to($sidebar-respond) {
				padding-right: 0;
				float: right;
				width: 65%;
			}
		}
	}
}

// inline with hero text
.l-sidebar__text__content {
	max-width: 800px;
	@include respond-to($sidebar-respond) {
		margin-left: calc(50% - 280px);
		margin-right: calc((50% - 400px) + 10%);
	}
	@include respond-to($e-vp) {
		margin-right: calc((50% - 400px) + 7%);
	}
}

.l-sidebar__body__content {
	@include respond-to($sidebar-respond) {
		margin-right: 20%;
	}
}


// ------------------------------------------------------------
// Offset
// ------------------------------------------------------------

.l-offset {
	position: relative;
	@extend %l;
	&__aside {
		position: relative;
		img {
			@extend %max-width;
		}
		&:before {
	        content: "";
	        position: absolute;
	        z-index: -1;
	        top: -3em;
	        right: -3em;
	        width: 50%;
	        height: 110%;
	        display: block;
	        background-size: 40%;
	        background-image: url(../img/coral-dots.png);
	        html.svg & {
	            background-size: 10%;
	            background-image: url(../svg/source/coral-dots-02.svg);
	        }
	    }
		@include respond-to($c-vp) {
			float: right;
			display: inline-block;
			width: 40%;
		}
	}
	&__main {
		margin-bottom: 3em;
		@include respond-to($c-vp) {
			width: 55%;
			float: left;
			display: inline-block;
			margin-bottom: 0;
		}
	}
	&--reverse {
		.l-offset__aside {
			&:before {
				left: -3em;
			}
			@include respond-to($c-vp) {
				float: left;
				display: inline-block;
				width: 40%;
			}
		}
		.l-offset__main {
			margin-bottom: 2em;
			@include respond-to($c-vp) {
				width: 55%;
				float: right;
				display: inline-block;
				margin-bottom: 0;
			}
		}
	}
}

// ------------------------------------------------------------
// Listings
// ------------------------------------------------------------

.l-listing {
	@extend %l;
}

// ------------------------------------------------------------
// 2 column grid
// ------------------------------------------------------------
.l-2up {
	@extend %l;
	@include respond-to($c-vp) {
		@supports (display: grid) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 40px;
		}
		@supports (display: -ms-grid) {
			display: block;
		}
	}
	&__col, .card, .teaser__item {
		position: relative;
		margin-bottom: 3em;
		@include respond-to($c-vp) {
			float: left;
			width: 47.5%;
			margin-right: 5%;
			&:nth-child(2n) {
				margin-right: 0;
			}
			@supports (display: grid) {
				width: auto;
				margin-right: 0;
			}
			@supports (display: -ms-grid) {
				float: left;
				width: 47.5%;
				margin-right: 5%;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}
}

// l-2up--float
.l-2up-float {
	@extend %l;
	margin-bottom: 3em;
	@include respond-to($c-vp) {
		> * {
			float: left;
			width: 47.5%;
			margin-right: 5%;
			margin-bottom: 0;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
}

// ------------------------------------------------------------
// 2 column grid large
// ------------------------------------------------------------
.l-2up-large {
	@extend %l;
	@include respond-to($d-vp) {
		@supports (display: -ms-grid) {
			display: block;
		}
		@supports (display: grid) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 40px;
		}
	}
	&__col, .teaser__item {
		margin-bottom: 3em;
		@include respond-to($d-vp) {
			float: left;
			width: 47.5%;
			margin-right: 5%;
			&:nth-child(2n) {
				margin-right: 0;
			}
			@supports (display: -ms-grid) {
				float: left;
				width: 47.5%;
				margin-right: 5%;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
			@supports (display: grid) {
				width: auto;
				margin-right: 0;
			}
		}
	}
}

// ------------------------------------------------------------
// 2 column offset
// ------------------------------------------------------------

.l-2up-offset {
	@extend %l;
	.l-2up__col {
		margin-bottom: 1em;
		margin-right: 0;
		@include respond-to($c-vp) {
			float: left;
			margin-bottom: 0;
			width: 56.25%;
			+.l-2up__col {
				width: 43.75%;
			}
		}
	}
}

// ------------------------------------------------------------
// 2/3 grid
// ------------------------------------------------------------

.l-2-3up {
	@extend %l;
	@include respond-to($c-vp) {
		@supports (display: -ms-grid) {
			display: block;
		}
		@supports (display: grid) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 40px;
		}
	}
	.l-2-3up__col {
		margin-bottom: 1em;
		@include respond-to($c-vp) {
			float: left;
			width: 66.66%;
			@supports (display: -ms-grid) {
				float: left;
				width: 66.66%;
			}
			@supports (display: grid) {
				width: auto;
				margin-right: 0;
				grid-column: 1 / 3;
			}
			+.l-2-3up__col {
				width: 33.33%;
				@supports (display: -ms-grid) {
					width: 33.33%;
				}
				@supports (display: grid) {
					width: auto;
					grid-column: 3 / 3;
				}
			}
		}
	}
}

// ------------------------------------------------------------
// 3 column grid
// ------------------------------------------------------------
.l-3up {
	@extend %l;
	@include respond-to($c-vp) {
		@supports (display: grid) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 40px;
			.footer & {
				grid-template-columns: 2fr 1fr 1fr;
			}
		}
		@supports (display: -ms-grid) {
			display: block;
		}
	}
	&__col, .card {
		margin-bottom: 3em;
		@include respond-to($c-vp) {
			float: left;
			width: 30%;
			margin-right: 5%;
			&:nth-child(3n) {
				margin-right: 0;
			}
			&:nth-child(3n+1) {
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-right: 0;
			}
		}
		@supports (display: -ms-grid) {
			@include respond-to($c-vp) {
				float: left;
				width: 30%;
				margin-right: 5%;
				&:nth-child(3n) {
					margin-right: 0;
				}
				&:nth-child(3n+1) {
					clear: left;
				}
			}
		}
		.l-3up--key & {
			@include respond-to-max($c-vp) {
				width: 75%;
				float: left;
				&:nth-child(2n) {
					width: 75%;
				}
				&:nth-child(3n) {
					width: 20%;
					margin-left: 5%;
					margin-top: 25%;
				}
			}
			@include respond-to($c-vp) {
				width: 40%;
				margin: 0;
				&:last-child {
					width: 20%;
				}
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
			@supports (display: grid) {
				width: auto;
				margin: 0;
				&:nth-child(2n) {
					width: auto;
				}
				&:nth-child(3n) {
					width: auto;
					margin: 0;
				}
				&:last-child {
					width: auto;
					align-self: center;
					grid-column-start: 2;
    				grid-row-start: 1;
				}
			}
			@include respond-to($c-vp) {
				@supports (display: grid) {
					width: auto;
					margin-right: 0;
					&:last-child {
						width: auto;
						grid-column-start: inherit;
	    				grid-row-start: inherit;
	    			}
				}
				@supports (display: -ms-grid) {
					display: block;
				}
			}
		}
	}
	&--key {
		@supports (display: grid) {
			display: grid;
			grid-template-columns: 1fr 0.4fr;
			grid-column-gap: 40px;
		}
		@supports (display: -ms-grid) {
			display: block;
		}
		@include respond-to($c-vp) {
			@supports (display: grid) {
				display: grid;
				grid-template-columns: 1fr 1fr 0.4fr;
			}
			@supports (display: -ms-grid) {
				display: block;
			}
		}
	}
}

// ------------------------------------------------------------
// 3-2column grid
// ------------------------------------------------------------
.l-3-2up {
	@extend %l;
	@include respond-to($b-vp) {
		@supports (display: grid) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 30px;
		}
		@supports (display: -ms-grid) {
			display: block;
		}
	}
	@include respond-to($c-vp) {
		@supports (display: grid) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 40px;
		}
		@supports (display: -ms-grid) {
			display: block;
		}
	}
	&__col, .card {
		margin-bottom: 3em;
		@include respond-to($b-vp) {
			float: left;
			width: 47.5%;
			margin-right: 5%;
			&:nth-child(2n) {
				margin-right: 0;
			}
      &:nth-child(2n+1) {
        clear: left;
      }
			@supports (display: grid) {
				width: auto;
				margin-right: 0;
			}
			@supports (display: -ms-grid) {
				float: left;
				width: 47.5%;
				margin-right: 5%;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
		@include respond-to($c-vp) {
			float: left;
			width: 30%;
			margin-right: 5%;
			&:nth-child(2n) {
				margin-right: 5%;
			}
		      &:nth-child(2n+1) {
		        clear: none;
		      }
			&:nth-child(3n) {
				margin-right: 0;
			}
		      &:nth-child(3n+1) {
		        clear: left;
		      }
			@supports (display: grid) {
				width: auto;
				margin-right: 0;
			}
			@supports (display: -ms-grid) {
				float: left;
				width: 30%;
				margin-right: 5%;
				&:nth-child(2n) {
					margin-right: 5%;
				}
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}
}

// ------------------------------------------------------------
// 4 column grid
// ------------------------------------------------------------
.l-4up {
	@extend %l;
	@include respond-to($b-vp) {
		@supports (display: grid) {
			display: grid;
			grid-template-columns: repeat(2, 47.5%);
			grid-column-gap: 5%;
		}
		@supports (display: -ms-grid) {
			display: block;
		}
	}
	@include respond-to($d-vp) {
		@supports (display: grid) {
			display: grid;
			grid-template-columns: repeat(4, 21.25%);
			grid-column-gap: 5%;
		}
		@supports (display: -ms-grid) {
			display: block;
		}
	}
	&__col, .card {
		margin-bottom: 3em;
		@include respond-to($b-vp) {
			float: left;
			width: 47.5%;
			margin-right: 5%;
			&:nth-child(2n) {
				margin-right: 0;
			}
			@supports (display: grid) {
				width: auto;
				margin-right: 0;
			}
			@supports (display: -ms-grid) {
				float: left;
				width: 47.5%;
				margin-right: 5%;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
		@include respond-to($d-vp) {
			float: left;
			width: 21.25%;
			margin-right: 5%;
			&:nth-child(2n) {
				margin-right: 5%;
			}
			&:nth-child(4n) {
				margin-right: 0;
			}
			@supports (display: grid) {
				width: auto;
				margin-right: 0;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
			@supports (display: -ms-grid) {
				float: left;
				width: 21.25%;
				margin-right: 5%;
				&:nth-child(2n) {
					margin-right: 5%;
				}
				&:nth-child(4n) {
					margin-right: 0;
				}
			}
		}
	}
}

// hidden
.hidden {
	overflow: hidden;
}
