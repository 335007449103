// ------------------------------------------------------------
// Additional style
// ------------------------------------------------------------

:root .form-field__checkboxes--styled,
:root .form-field__radio-buttons--styled {
	min-height: 30px;
	@extend %pie-clearfix;
	input[type="radio"],
	input[type="checkbox"] {
		position: relative;
		z-index: 0;
		top: auto;
		clip: rect(1px, 1px, 1px, 1px);
	    padding: 0px;
	    border: 0 !important;
	    height: 1px !important;
	    width: 1px !important;
	    overflow: hidden;
		position: absolute;
		&:focus {
			+ label {
				color: $action;
			}
		}
	}
	label {
	    cursor: pointer;
	    vertical-align: middle;
	    position: relative;
		z-index: 1;
		margin-left: 30px;
	}
}

// Checkboxes
:root .form-field__checkboxes--styled {
	label:before {
		content: "";
		display: inline-block;
		vertical-align: text-top;
		width: 20px;
		height: 20px;
		margin-right: 10px;
		margin-top: -1px;
		background-color: #fff;
		border: $default-border-width solid $default-border-color;
		border-radius: 3px;
		margin-left: -30px;
	}
	input:checked + label:before {
		background-color: $action;
		border: $default-border-width solid $action;
	}
	input:checked + label:after {
		position: absolute;
		content: "";
		top: 6px;
		left: -26px;
		width: 12px;
		height: 8px;
		border: 3px solid #fff;
		border-top: none;
		border-right: none;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		z-index: 3;
	}
}

// Radio
:root .form-field__radio-buttons--styled {
	label:before {
		content: "";
		display: inline-block;
		vertical-align: text-top;
		width: 20px;
		height: 20px;
		margin-right: 10px;
		margin-top: -1px;
		background-color: #fff;
		border: $default-border-width solid $default-border-color;
		border-radius: 50%;
		margin-left: -30px;
	}
	input:checked + label:after {
		position: absolute;
		content: "";
		top: 7px;
		left: -25px;
		height: 10px;
		width: 10px;
		background: $form-radio-color;
		border-radius: 50%;
		z-index: 3;
	}
}
