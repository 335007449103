// ------------------------------------------------------------
// countries
// ------------------------------------------------------------

@use "sass:math";

.countries-wrapper {
	position: relative;
	overflow: hidden;
	z-index: 1;
	@include respond-to($d-vp) {
		width: 45%;
		float: left;
	}
}


.countries-search {
	display: block;
	margin-bottom: 2rem;
	.countries-search__form {
		border: 1px solid $gnr-slate;
		&::placeholder {
			color: $gnr-slate;
		}
	}
	html.no-js & {
		display: none;
	}
}

.countries-search-nav {
	@include respond-to($d-vp) {
		transition: $transition;
	}
}

.countries-info {
	margin-bottom: 4rem;
	@include respond-to($d-vp) {
		float: right;
		right: 4rem;
		top: $internal-spacing;
		width: calc(50% - 4rem);
		transition: $transition;
		pointer-events: auto;
		opacity: 1;
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
		margin-left: 0;
		margin-right: 0;
	}
	@include respond-to-max($d-vp) {
		.button {
			display: block;
			margin-left: 0;
			margin-bottom: 1em;
		}
	}
	.para {
		margin-bottom: 2rem;
	}
	&.show {
		position: absolute;
		pointer-events: none;
		opacity: 0;
		transform: translateX(0px);
		-webkit-transform: translateX(0px);

	}
	p:last-of-type {
		margin-top: 2rem;
	}
}

.countries-info__image {
	max-width: 100%;
	margin-bottom: 2rem;
	img {
		display: block;
		max-width: 100%;
		max-height: 300px;
		@include respond-to($d-vp) {
			max-height: 100%;
		}
		@include respond-to($e-vp) {
			max-width: 600px;
		}
	}
}

.countries-info__heading {
	@extend %font-heading;
	@include font-size($alpha + 5);
	line-height: 1.2;
	margin-bottom: 2rem;
	@include respond-to($menu-respond) {
	    @include font-size(math.div($alpha-papa, 1.25));
	    max-width: 550px;
	}
}

// ------------------------------------------------------------
// countries-search-searched
// ------------------------------------------------------------
.countries__searched {
	display: none;
	margin-bottom: 4rem;
	&.active {
		display: block;
	}
	@include respond-to($menu-respond) {
		html.js & {
			position: absolute;
			width: 100%;
		}
	}
}

.countries__searched__list {
	padding: 2rem;
	background: $gnr-slate;
	@include font-size(math.div($zeta, 1.25));
	@include respond-to($c-vp) {
		@include font-size($zeta);
	}
}

.countries__searched__item {
	padding: 0.75rem 0;
	color: $body-color;
}

.countries__searched__parent--first {
	text-transform: uppercase;
	letter-spacing: 1px;
	@extend .heading;
}

.countries__searched__children {
	padding-left: 2rem;
}

.countries__searched__parent--second {
	text-transform: uppercase;
	letter-spacing: 1px;
}

.countries__searched__children__sub {
	padding-left: 2.5rem;
}

.countries__searched__country {

}

.countries__searched__highlight {
	padding: 0.5rem 1rem;
	background: rgba($gnr-sunrise, 0.5);
	color: #fff;
	transition: $transition;
	&:hover {
		background: rgba($gnr-sunrise, 0.75);
	}
}

.countries__searched__highlight__typed {
	padding: 0.5rem 0 0.5rem;
	background: $gnr-sunrise;
	color: #fff;
}

// ------------------------------------------------------------
// countries-navigation-list
// ------------------------------------------------------------
.countries-menu-list {
	position: relative;
	margin: 0 0 6rem 0;
	&.inactive {
		display: none;
	}
}

.countries-menu-list__content {
	html.js & {
		position: absolute;
		width: 100%;
		z-index: 2;
	}
}

.countries-menu-list__item {
	position: relative;
	display: block;
	padding: 14px 80px 14px 20px;
	background: darken($gnr-slate, 15%);
	box-shadow: inset 0 -1px $gnr-cloud;
	color: $body-color;
	transition: $transition;
	@include font-size(math.div($zeta, 1.25));
	em {
		margin-right: 20px;
		font-style: normal;
	}
	@include respond-to($c-vp) {
		padding: 18px 80px 18px 24px;
		@include font-size($zeta);
	}
	&--open ~ .countries-menu__toggle {
		background-image: url(/assets/svg/source/icon-cross-white.svg);
	}
	a {
		color: $body-color;
	}
	&:hover, &:focus {
		background: $action;
		color: $body-color;
	}
	.countries-menu-list__countries--selected & {
		background: $action;
		color: $body-color;
	}
	&.active {
		// background: $action;
	}
}

.countries-menu-list--has-children, .countries-menu-list--no-children {
	position: relative;
	ul {
		display: none;
		&.countries-menu-list--selected {
			display: block;
		}
	}
}


.countries-menu-list__countries {
	position: relative;
}

.countries-menu-list__parent {
	@extend .heading;
	box-shadow: none;
	&:hover {
		background: darken($gnr-slate, 15%);
	}
}

.countries-menu-list__item--parent-first {
	@extend .heading;
	background: darken($gnr-slate, 7%);
	@include respond-to($c-vp) {
		padding-left: 4rem;
	}
	&.active {
		background: $action;
	}
}

.countries-menu-list__item--parent-second {
	background: lighten($gnr-slate, 5%);
	@include respond-to($c-vp) {
		padding-left: 6rem;
	}
	&.active {
		background: $action;
	}
}

.countries-menu-list__item--parent-third {
	background: $gnr-concrete;
	color: $gnr-slate-a11y;
	@include respond-to($c-vp) {
		padding-left: 8rem;
	}
	.countries-menu-list__countries--selected & {
		background: $gnr-sunrise;
	}
}

.countries-menu__toggle {
	display: inline-block;
	box-shadow: none;
	padding: 0;
	position: absolute;
	z-index: 20;
	top: 0;
	right: 0;
	height: 100%;
	padding: 1.5rem 3rem;
	background-color: rgba(0, 0, 0, 0.25);
	background-image: url(/assets/svg/source/icon-plus-white.svg);
	background-size: 15px;
	background-position: center 16px;
	background-repeat: no-repeat;
	border-left: 1px solid $gnr-cloud;
	@include respond-to($c-vp) {
		background-position: center 23px;
	}
	html.no-js & {
		display: none;
	}
	&:hover {
		background-color: $action;
	}
}
