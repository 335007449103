// ------------------------------------------------------------
// Sections
// ------------------------------------------------------------

@use "sass:math";

.section {
    position: relative;
    @extend .padd-section-top;
    &:last-of-type,
    &--padd-bottom {
        @extend .padd-section-bottom;
    }
    &--alt {
        background-color: lighten($gnr-sky-light, 10%);
        @extend .padd-section-bottom;
    }
    &--alt-grey {
        background-color: lighten($gnr-cloud, 10%);
    }
    &--flourish {
        margin-top: $internal-spacing*0.5;
    }
    &--flourish-alt {
        margin-top: $internal-spacing*0.5;
        .sectionHighlight:before {
            background-image: url(../svg/source/coral-dots-02.svg) !important;
        }
    }
}

.section__divider {
    display: block;
    background-color: lighten($gnr-cloud, 5%);
    height: 3px;
    margin-bottom: 3em;
}

.section__heading {
    @extend %font-heading;
    @include font-size($alpha);
    margin-bottom: 0.25em;
}

.section__subheading {
    @include font-size($gamma);
    margin-bottom: 0.25em;
}

// ------------------------------------------------------------
// Chapter sub-sections
// ------------------------------------------------------------

.section-chapter {
    position: relative;
    @include respond-to($d-vp + 150px) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -90%;
            height: 150%;
            width: 100%;
            display: block;
            background-size: 15%;
            background-image: url(../img/orange-dots.png);
            html.svg & {
                background-size: 2%;
                background-image: url(../svg/source/orange-dots-02.svg);
            }
        }
    }
}

.section-chapter__number {
    @extend %font-heading;
    @include font-size($beta);
    color: $gnr-sunset;
    border-bottom: $border-width solid $gnr-sunset;
    margin-bottom: 0.5em;
    display: inline-block;
    @include respond-to($d-vp + 150px) {
        @include font-size($alpha + 8);
        position: absolute;
        top: 0.25em;
        left: 0;
        line-height: 1;
    }
}

.section-chapter__link {
    @include font-size($eta);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1em;
    display: block;
    @extend .heading;
    text-decoration: underline;
    .ico {
        margin-right: 5px;
        top: -1px;
    }
}

.section-chapter__heading {
    @extend %font-heading;
    @include font-size(math.div($alpha, 1.25));
    line-height: 1.3;
    @include respond-to($menu-respond) {
        @include font-size($alpha + 8);
        max-width: 70%;
        .section--header & {
            width: 45%;
            float: left;
        }
    }
}
